// SCSS partial
// COMPONENT = a grid of icons
//
//
// **********

// grids of icons in project pages
// used in
// - sierra project
// - iconography project

$iconography-icon-space-bottom: 2rem;
$iconography-icon-space-horizontal: 1rem;

// general styles for generated grids of icons
.project-sprite-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 2rem;
    margin-bottom: -1rem; // there will be extra margin space on bottom because of the margins on each iconElement in the grid. so adjust back up.
}

.iconElement {
    margin-bottom: $iconography-icon-space-bottom;
    padding: 0 $iconography-icon-space-horizontal;
}
