// project-page specific styles
//
// alpine data
// sierra redesign
// SIERRA iconography gallery
@use "sass:math";


$iconography-icon-space-bottom:     2rem;
$iconography-icon-space-horizontal: 1rem;

// dimensions of the source (original) image @1x scale
$project-background-image-width: 1050px; // physical dimensions of @1x image. WIDTH.
$project-background-image-height: 900px; // physical dimensions of @1x image. HEIGHT.

$project-image-size-scale: 0.7; // proportion of original to scale the image, if at all. set to 1 for no scale.

$project-icon-dimension: 120px * $project-image-size-scale; // physical dimentions of the area that should be displayed.
$project-sprite-grid-dimension: 150px; // physical dimentions of an individual sprite cell. assumed square
// $project-backgroundSize-proportion-width: $project-background-image-width / $project-icon-dimension;
$project-backgroundSize-proportion-width: math.div($project-background-image-width, $project-icon-dimension);

// $project-backgroundSize-proportion-height: $project-background-image-height / $project-icon-dimension;
$project-backgroundSize-proportion-height: math.div($project-background-image-height, $project-icon-dimension);

$project-background-size-width: percentage($project-backgroundSize-proportion-width * $project-image-size-scale);
$project-background-size-height: percentage($project-backgroundSize-proportion-height * $project-image-size-scale);

$project-sprite-size: $project-icon-dimension * $project-image-size-scale;


#sierra-icon-grid {
  justify-content: center;

  // negative margin to negate the left & right padding within each iconElement
  margin-left: -$iconography-icon-space-horizontal;
  margin-right: -$iconography-icon-space-horizontal;

  .icon-image {
    width: 100%;
    height: 0;
    padding-top: 100%;

    // default image for @1x display
    background-image: url($images-path-portfolio + "alpine/sierra/icons/sierra-icon-compilation@1x.png");
    background-size: $project-background-size-width, auto;
    background-repeat: no-repeat;
  }
}

// image for @2x display
@include mediaquery-for-image-2x() {
  #sierra-icon-grid {
    .icon-image {
      background-image: url($images-path-portfolio + "alpine/sierra/icons/sierra-icon-compilation@2x.png");
    }
  }
}
