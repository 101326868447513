// PROJECTS
// styles for the project pages
// and individual projects

@use "sass:math";

$project-primarynavbar-background-color: $site-primarynavbar-background-color;
// $project-primarynavbar-background-color-initial: $site-primarynavbar-background-color;
// $project-header-background-color-scrolled:  lighten($project-primarynavbar-background-color, 3%);

$page-header-space-top:                     15px;

$page-section-spacing-top:                  0;
$page-section-spacing-bottom:               3.5rem;
$page-subsection-spacing-top:               .5rem;
$page-subsection-spacing-bottom:            1rem;

$page-section-special-spacing-top:          6rem;

$project-layout-space-between-column:       20px;

$projectName-font-size-lg:                  3.5rem;
$projectName-line-height:                   1.1;
$company-font-size-lg:                      1.7rem;

// $page-section-border-bar-color:             #A2A29E;
$page-section-border-bar-color:             #92928e;

$nav-link-padding:                          0 0 1px 0;

$project-aside-section-padding-top:         .5rem;  // base margin at top
$project-aside-padding-top:                 $project-aside-section-padding-top;  // used for the right-side sidebar content

$list-padding: 10px;

// $side-item is used for the individual items in the right sidebar (tags, roles, etc) and the left table of contents
$aside-item-line-height:                 1.22rem;
$aside-item-margin-bottom:               .5rem;
$aside-item-heading-lineheight:          1.4rem;

$project-overview-font-size:    $project-h3-font-size-lg;
$project-overview-shift:        -8px;

// the navlink active font becomes bold, which widens it slightly. this is a hacky
// modifier to reduce the font size slightly to make it less wide
$font-size-adjustment-for-active: 98.1%;

@mixin next-widget-button {
    font-size: 1.4rem;
    line-height: 1;
}

@mixin section-border-bar ($border-side: "border-top") {
    #{$border-side}: 1px dotted $page-section-border-bar-color;
}

// @mixin sidebar-z-index {
//     position: relative;
//     z-index: 30;
// }

// shared mixin for sidebar text
@mixin sidebarText {
    line-height: $aside-item-line-height;
    margin-bottom: $aside-item-margin-bottom;
}

// @mixin row-margin-remove {
//     margin-right: 0;
// }



// **********

section {
    li {
        padding-left: $list-padding; // space between the bullet and the text
    }
}


ul, ol {

    // quirky typography on the list items in body content
    @at-root .page-section + & {
        margin-left: - (40 + $list-padding);
        content: "blog";
    }

    // tuck the list up closer to the previous paragraph
    // &.list-tuckup {
    //     margin-top: -.8rem;
    // }

    // adjust margins when list is after a p.
    // this replaces the overt ".list-tuckup"
    @at-root p + & {
        margin-top: -0.5rem;
    }

    // amend the default list-unstyled class to
    &.list-unstyled {
        li {
            padding-left: 0;
        }
    }
    // a quirky, special sort of list in the content
    &.article {
        margin-left: -$list-padding;
        margin-top: -.75rem;
        padding: 0;
    }



    &.list-prominent {
        font-size: 1.3rem;
    }

    &.list-emphasis {
        font-weight: $font-weight-semibold;

        p {
            font-weight: $font-weight-normal;  // turn off the emphasis on the child element
        }
    }

    // alpha lists
    &.list-alpha {
        list-style-type: upper-alpha;
    }

    // lists w/o bullets. remove the bullets
    &.list-nobullet {
        list-style-type: none;
    }

    // more spacing between each list item
    &.list-separated {
        li {
            margin-bottom: 1rem;
        }
    }
    // a kind of callout list.
    // lists already indent on left. so just apply to the right
    &.list-inset {
        margin-right: 3rem;
    }
}



.blockquote {
    // override boostrap styles
    $spacer:   2rem;

    padding: math.div($spacer, 4) 0 math.div($spacer, 2) 0;

    font-size: 1.5rem;
    font-style: italic;
    border: none;
}



.mainColumn {
    z-index: 0;  // TODO: needs this to make content links active...maybe because of the crazy heading anchor elements?
    font-size: 15px;
}

@include media-breakpoint-up(md) {
    .mainColumn {
        font-size: 16px;
    }
}

// .theme-project {


    // layout spacing adjustment
    // to give a little more breating room between the columns in the project pages
    // .leftColumn {
        // padding-right: 0.5rem;
    // }

    // .rightColumn {
        // padding-left: 0.5rem;
        // padding-left: 0;
        // padding-right: 0;
    // }
// }

@include media-breakpoint-up(lg) {
    // .theme-project {
        // .rightColumn {
            // padding-left: $project-layout-space-between-column;
        // }
    // }
}

.project-header,
.page-header {
    // margin-top: $project-primarynavbar-height;
    padding-top: $project-primarynavbar-height + $page-header-space-top;
    padding-bottom: 10px;

    // * DISABLED
    // TODO: some of these styles are involved in attempt to make the project 'header' fix to the top of the page, and change styles (smaller text)
    // when scrolled down the page.
    // background-color: #F1F1DF;  // TODO dev
    // opacity: .7;    // TODO dev
    // position: relative;  // TODO dev
    // position: sticky;    // TODO dev
    // top: 40px;
    // z-index: 999999;    // TODO dev
}

// header on general page layout
.page-header {
    margin-bottom: 1rem;

    h1 {
        position: relative;
        left: -2px;
        font-weight: $font-weight-bold;
        font-size: $projectName-font-size-lg;
        letter-spacing: -0.02rem;
        word-spacing: 0.05rem;
    }
}


// header on project pages layout
.project-header {
    margin-bottom: 3rem;

// * DISABLED
// TODO: animation for when the project 'header' is fixed to the top of the page, and changes styles (smaller text)
//     * {
//         transition: all 0.25s ease;
//     }
    .company {
        margin: 0;
        font-weight: $font-weight-semibold;
        font-size: $company-font-size-lg;
    }

    h1 {
        // typographic tweaks...
        position: relative;
        // to make the left margin appear more even when font size is so large
        left: -2px;
        // pul the header up slightly for better line spacing with the company name above
        top: -2px;
        font-weight: $font-weight-semibold;
        font-size: $projectName-font-size-lg;
        line-height: $projectName-line-height;
        letter-spacing: -0.03rem;
        word-spacing: 0.05rem;
    }

}

// content-page specific styles
.theme-project {

    .top-navbar {
        height: $project-primarynavbar-height;

        background-color: $project-primarynavbar-background-color;
        border-bottom: 1px solid desaturate(darken($project-primarynavbar-background-color, 19%), 9%);
    }

    // should only be applied to a <section>
    .page-section {
        margin-top: $page-section-spacing-top;
        margin-bottom: $page-section-spacing-bottom;

        &+.page-subsection {
            margin-top: -1rem;
        }
    }

    // should only be applied to a special <section> for more vertical space
    .page-section-special {
        margin-top: $page-section-special-spacing-top;
    }

    .page-subsection {
        padding-top: $page-subsection-spacing-top;
        margin-bottom: $page-subsection-spacing-bottom;
    }

    // h2 followed immediately by h3
    h2 + h3 {
        margin-top: 1rem;
    }

    // adding extra space above the div when it comes right after a heading
    h2 {
        & + div {
            padding-top: 1.5rem;
        }
    }

    h3, h4 {
        & + div {
            padding-top: 1rem;
        }
    }

    .project-case-link {
        padding: 0;
        margin-top: -.75rem;
        margin-bottom: 2rem;
        font-weight: $font-weight-semibold;
    }
}


// bigger
@include media-breakpoint-up(md) {

    .theme-project {

        // should only be applied to a <section>
        .page-section {
            margin-top: $page-section-spacing-top;
            margin-bottom: $page-section-spacing-bottom;

            &+.page-subsection {
                margin-top: -1rem;
            }
        }

        // should only be applied to a special <section> for more vertical space
        .page-section-special {
            margin-top: $page-section-special-spacing-top;
        }

        .page-subsection {
            padding-top: $page-subsection-spacing-top;
            margin-bottom: $page-subsection-spacing-bottom;
        }

        // h2 follwed immediately by h3
        h2 + h3 {
            margin-top: 1.5rem;
        }

        // adding extra space above the div when it comes right after a heading
        h2 {
            & + div {
                padding-top: 1.5rem;
            }
        }
        h3, h4 {
            & + div {
                padding-top: 1rem;
            }
        }
    }

}

.project-overview {
    // background-color: #15f2EF;
    // small shift of entire block upwards for better visual alignment of the text with the sidebar rules
    // position: relative;  // already declared relative because it is a '.theme-project section' in 'page_scrollfix'
    margin-top: $project-overview-shift !important;

    > p,
    > ul,
    > ol {

        margin-bottom: 1.5rem;

        font-family: $font-family-primary;
        font-weight: 500;
        font-size: $project-overview-font-size;
        line-height: 1.5;
    }
}


// smallest, and up
@include media-breakpoint-up(xs) {

    .project-overview {
        margin-bottom: 2.5rem;
    }

    .project-image {
        margin-bottom: 1rem;
    }
}

// medium, and up
@include media-breakpoint-up(md) {

    .project-overview {
        margin-bottom: 4.5rem;
    }

    .project-image {
        margin-bottom: 1.5rem;
    }
}


@include media-breakpoint-down(md) {

    .project-overview {
            > p,
            > ul,
            > ol {
                font-size: ($project-overview-font-size * 0.93);
                line-height: 1.4;
            }
        }
}


// custom image/figure sizes
// generates a set of rules based on the sizes in the map
$figure-sizes: (
    xx-small: 30%,
    // x-small:  35%,
    small:    50%,
    medium:   70%,
    large:    80%,
    full:     100%,
);

@each $size, $width in ($figure-sizes) {
    .project-image {
        figure.figure-#{$size} {
            width: $width;
        }
    }
}

.project-image {

//     &:last-child {
//         background-color: #F66;  // TODO DEV. reveals some odd inconsistencies from page to page in image
//     }

    &.image-centered {
        text-align: center;
    }

    &.image-inline {
        display: inline;

        figure {
            float: left;
            margin: 0;
        }
    }
}

// catch all of the widgets that might come after content and add more space above
p,
ul,
li {
    & + .project-image {
        margin-top: 2rem;
    }
}

.theme-project {
    figure {
        &.figure-bordered {
            .figure-image,
            .figure-content {
                @include subtle_border ($default-border-color);
            }

            // img {
                // @include subtle_border ($default-border-color);
            // }
        }

        .figure-image-bordered {
            @include subtle_border ($default-border-color);
            img {
                display: block;
            }
        }

        .figure-image {
            overflow: hidden;
        }
    }

    .figure-img {
        margin-bottom: 0;  // override BS default
    }

    // .figure-caption {}
    figcaption,
    .caption {

        @include image-caption-position;
        @include image-caption-text;

        // .theme-project {
        //     .caption {
        //         @include image-caption-position;
        //         @include image-caption-text;

        //         .credit {
        //             margin-top: 4px;
        //             margin-bottom: 0;
        //         }
        //     }
        // }
    }

    img {
        &.img-bordered {
            @include subtle_border ($default-border-color);
        }
    }

// not in active use
//     .project-figure {
//         background-color: #9DE6FF;
//
//         .figure-content {
//             padding: .5rem 1rem 1.5rem 1rem;
//         }
//
//         .figure-table {}
//     }

    footer {
        background-color: $project-footer-background-color;
        color: $text-color-black-lighter3;
    }
}


// image handling when the image displayed is a mobile screenshot
.img-mobile {
    width: 50%;
    margin-top: 1rem;
    margin-bottom: 2rem;

    .project-carousel & {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

// at smaller sizes, the image can be larger
@include media-breakpoint-down(lg) {
    .figure-mobile {
        width: 75%;
    }
}

// default for larger screen sizes
@include media-breakpoint-up(lg) {
    .figure-mobile {
        width: 50%;
    }
}

// fix for 100% images on ie.
.img-width-100 {
    width: 100%;
}

// page section
// in projects that contains the categories, roles, related projects
.project-aside {
    aside {
        margin-bottom: 5px;
        padding: $project-aside-padding-top 0 5px 0;
        @include section-border-bar;
    }

    .list-unstyled {
        li {
            @include sidebarText;
        }
    }

    h4 {
       margin: 0 0 .4rem 0;
       font-size: inherit;
    //    font-weight: $font-weight-semibold;
       // line-height: $aside-item-heading-lineheight;
    }
//     .project-tags,
//     .project-roles,
//     .project-related,
//     .project-team {
//         // @include sidebar-z-index;
//     }
//     .project-tags,
//     .project-roles,
//     .project-related {
//         @include section-border-bar;
//     }
    // a {
        // font-family: $font-family-secondary;
        // font-size: #{$font-secondary-scale}rem;
    // }
}

.project-next-project {
    align-items: center;  // flex
    padding: 5px 0;
    border-radius: 3px;
    background-color: $project-next-project-background-color;

    .btn {
        @include next-widget-button;
    }

    .projectlink {
        max-width: 42%;
        margin: 0 5px 1px 5px;

        a {
            font-family: $font-family-secondary;
            font-size: #{$font-secondary-scale}rem;  // visual typographic adjustment. proportional to the labeling text
        }
    }

    .projectname {
        font-size: .89rem;
        line-height: 1.3;
    }
}

// bigger
@include media-breakpoint-up(xl) {
    .project-next-project {
//         margin-top: 2.0rem;
//         margin-bottom: 1rem;

        .projectname {
            font-size: 1rem;
            line-height: 1.35;
        }
    }
}


// bigger
// @include media-breakpoint-up(md) {
//
//     .project-next-project {
// //         margin-top: 3.0rem;
// //         margin-bottom: 1rem;
//     }
//
// }


.footerBar {
    // z-index: -1;
    // position: relative;
    width: 100%;
    height: 1px;
    border-top: 1px dotted darken($project-next-project-background-color, 25.0%);
}

.bottomWidget {
    margin-top: 2.0rem;
}
