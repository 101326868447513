// main style file
// GENERAL common, shared style definitions

$page-h1-font-size-xl:      3.5rem;

$project-h2-font-size-lg:   2.0rem;
$project-h2-font-size-md:   1.91rem;

$project-h3-font-size-lg:   1.6rem;
$project-h3-font-size-md:   1.47rem;

$project-h4-font-size-lg:   1.22rem;
$project-h4-font-size-md:   1.12rem;

$project-h5-font-size:      inherit;

$project-h2-margin-top-md:     3.25rem;
$project-h2-margin-top-sm:     2.25rem;
$project-h3-margin-top-md:     2.75rem;
$project-h3-margin-top-sm:     1.75rem;
$project-h4-margin-top-md:     1.35rem;
$project-h4-margin-top-sm:     1rem;




// TYPOGRAPHY */

body {
    position: relative;
    font-family: $font-family-primary;
}

// links. obviously.
a {
    color: $theme-link-color;  // $link-color-primary
    text-decoration: $theme-link-decoration;  // none

    &:hover {
        color: $theme-link-hover-color;
        text-decoration: $theme-link-hover-decoration;  // none
    }
}

// global defaults for ALL nav-links
.nav-link {

    // default transition for nav-link. will apply when un-hovering a link
    transition: color $nav-item-color-transition-duration-long ease;

    &.active {
        font-weight: $font-weight-semibold;
        color: $text-color-nav-default;
    }
    &:not(.active):hover {
        color: $link-color-highlight;
        transition: color $nav-item-color-transition-duration ease;
    }
}



// h5 {
//     margin: 1.1rem 0 1px 0;
// }
// .h5 {
//     margin: 1.1rem 0 1px 0;
// }

.nobr {
    white-space: nowrap;
}

// SECTION TYPES

.theme-section {
    position: relative;
    padding-top: 9rem;
    padding-bottom: 9rem;
}

.theme-section-full {
    display: table;
    width: 100%;
    height: 100vh;
    padding-top: 0;
    padding-bottom: 0;
}

.theme-section-background {
    @include background-image-50-50;
    @include background-image-cover-fixed;
}

// on smaller screen, aka mobile, fixed background is a performance hit. so make it the default, 'scroll'
@include media-breakpoint-down(md) {
    .theme-section-background {
        background-attachment: scroll;
    }
}

.theme-section-small {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

// .theme-section-nopadding {
//     padding-top: 0;
//     padding-bottom: 0;
// }

.theme-table {
    display: table;
}

.theme-table-cell {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    padding-top: 6rem;
    padding-bottom: 6rem;
}
