// styles for the image zoom widget function
//

// zoom widget variables
//
// position of the zoom-indicator element (ie the corner cutout + icon)
$zoom-mark-position-top:    0;
$zoom-mark-position-right:  0;

$zoom-cornermarker-width:   40px;
$zoom-cornermarker-color:   rgba(#000000, 0.5);

// position the cutout notch: at the top right of the element
$zoom-corner-position-top:      0;
$zoom-corner-position-right:    0;

$zoom-corner-size:      50px;  // width (in square) of the notch
$zoom-corner-color:     rgba(#000000, 0.5);  //color of the notch

// width & height determine size of target hit area, expanded to make larger zone
// min = $zoom-corner-width
$zoom-hit-width:        $zoom-corner-size + 40;
$zoom-hit-height:       $zoom-corner-size + 40;

// icon glyph
$zoom-icon-position-x:          6px;
$zoom-icon-position-y:          5px;
$zoom-icon-font-size:           1rem;
$zoom-icon-glyph-color:         $core-white;

// transformations when hovering
$zoom-icon-font-size-hover:     1.6rem;
$zoom-corner-size-hover:        $zoom-corner-size + 15;

// transition used when returning to default non-hover state
@mixin zoom-transition-out {
    transition: all 0.25s ease-in-out;
}


// overrides for the regular figure image border behavior.
// the zoomable version requires a more complicated enveloping of the img. named ".image-zoom"
// turn on the border for the envelope, turn off the border on the figure image.
// position relative in order to make the icon position correctly on load.
.figure-bordered {
    &.zoom {
        .image-zoom {
            @include subtle_border ($default-border-color);
        }
    }
}

.image-zoomable {
    display: block;  // change to block: attempted fix for space underneath
    position: relative;

    // element overlaying the image with magnifying glass glyph
    .zoom-mark {
        z-index: 2;

        display: block;
        position: absolute;
        top: $zoom-mark-position-top;
        right: $zoom-mark-position-right;
        width: $zoom-hit-width;
        height: $zoom-hit-height;

        // css-generated corner 'notch' graphic
        .cutout {
            position: absolute;
            top: $zoom-corner-position-top;
            right: $zoom-corner-position-right;
            border-top: $zoom-corner-size solid $zoom-corner-color;
            border-left: $zoom-corner-size solid transparent;

            @include zoom-transition-out;
        }

        // magnifying glass icon
        .zoomicon {
            display: inline-block;

            position: absolute;
            top: $zoom-icon-position-y;
            right: $zoom-icon-position-x;

            font-size: $zoom-icon-font-size;
            color: $zoom-icon-glyph-color;

            @include zoom-transition-out;
        }
    }

    // animation effect for the zoom marker on hover
    &:hover {
        .zoom-mark {
            .cutout {
                border-top-width: $zoom-corner-size-hover;
                border-left-width: $zoom-corner-size-hover;
                transition: border 0.51s ease-in-out;
            }
            .icon {
                font-size: $zoom-icon-font-size-hover;
                transition: font 0.47s ease-out .04s;
            }
        }
    }

    img {
        display: block;
        border: 0;

        &::selection {
            background-color: transparent;
        }

        &:hover {
            @include cursor-grab;
        }

        &:active {
            @include cursor-grabbing;
        }
    }
}
