// project-page specific styles

// ca
// executive insight

.like-magic {
  z-index: -1;
  position: relative;
  left: -3px;
  top: -3px;
  display: inline-block;
  width: 1px;
  font-size: 2.9rem;
  line-height: 0;
}