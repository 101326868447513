// SCSS partial
//
// pure css close icon "x"
// from: https://codepen.io/ndeniche/pen/ljbDL
//
// **********



$closeicon-size:            25px;
$closeicon-thickness:       3.4px;  // thickness of the bars. visual attempting to match the style of the FA glyph used for the hamburger icon
$closeicon-rounding:        1.8px;
$closeicon-color-default:   $color-white-darker4;   // color of the graphic in normal state
$closeicon-color-hover:     $core-white;            // color of the graphic when hovered

$closeicon-transition-duration:    .25s;

.closeIcon {
    position: relative;
    display: inline-block;

    width: $closeicon-size;
    height: $closeicon-size;
    overflow: hidden;

    &:hover {
        &::before,
        &::after {
          background: $closeicon-color-hover;
        }
    }

    &::before,
    &::after {
        position: absolute;
        top: 49%; // visually adjust position upwards slightly from 50%?
        left: 0;

        content: "";
        width: $closeicon-size;
        height: $closeicon-thickness;
        background: $closeicon-color-default;
        border-radius: $closeicon-rounding;
        transition: background $closeicon-transition-duration ease;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}