// FONT PATH
//  * --------------------------

$fontFileBase:      "fontawesome";
$fontFileModifier:  "subset";
$fontFileName:      $fontFileBase + "." + $fontFileModifier;

@font-face {
  font-family: "FontAwesome";
  // src: url("#{$fa-font-path}/#{$fontFileName}.eot?v=#{$fa-version}");
  // src: url("#{$fa-font-path}/#{$fontFileName}.eot?#iefix&v=#{$fa-version}") format("embedded-opentype"),
  src: url("#{$fa-font-path}/#{$fontFileName}.woff2?v=#{$fa-version}") format("woff2"),
       url("#{$fa-font-path}/#{$fontFileName}.woff?v=#{$fa-version}") format("woff");
       // url("#{$fa-font-path}/#{$fontFileName}.ttf?v=#{$fa-version}") format("truetype");
       // url('#{$fa-font-path}/#{$fontFileName}.svg?v=#{$fa-version}#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}