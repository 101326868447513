// pill shape
// used for DOWNLOAD resume & portfolio link shapes

$pill-element-padding-sides:      2.1rem;
$font-size-smallcap:        75%;
$pill-element-lineheight:   3rem;

$pill-light-background-color:           #F9F9F8;
// $pill-light-background-color-hover:     rgba(245,248,255,0.52);
$pill-light-background-color-hover: rgba(84, 234, 94, 0.52);

$pill-dark-background-color:            #666679;
$pill-dark-background-color-hover:      rgba(245,248,255,0.42);


.pill {
    position: relative;

    // small visual adjustment to move upwards
    top: -2px;

    display: inline-block;
    padding: 2.5px $pill-element-padding-sides 3.5px $pill-element-padding-sides;
    margin-left: .7rem;

    border-radius: 1.7rem;
    font-weight: $font-weight-semibold;
    font-size: $font-size-smallcap;
    line-height: $pill-element-lineheight;

    transition: background-color 0.42s ease;

    &:first-child {
        margin-left: 0;
    }

    &.light {
        $color-base: $pill-light-background-color;
        $color-text: $core-white;
        @include pill ($color-base, $color-text);

        &:hover {
            background-color: $pill-light-background-color-hover;
        }
    }

    &.dark {
        $color-base: $pill-dark-background-color;
        $color-text: $core-black;
        @include pill ($color-base, $color-text);

        &:hover {
            background-color: $pill-dark-background-color-hover;
        }
    }
}
