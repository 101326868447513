// NAVBAR //

$top-navbar-transitiontime-in:  .9s;
$top-navbar-transitiontime-out: .3s;

$navbar-toggler-icon-font-size: 2.1rem;

.top-navbar {
    height: $homepage-primarynavbar-height;
    padding-top: 0;
    padding-bottom: 0;

    font-family: $font-family-secondary;

    // transition rule for the on-scroll style change of the primarynav header bar
    transition: background $top-navbar-transitiontime-out ease-in-out, border-color $top-navbar-transitiontime-out ease-in-out;

    // background-color: $site-primarynavbar-background-color-translucent;
    background-color: transparent;
    border-bottom: 1px solid transparent;  // put a border on the navbar in default state so that the 'scrolled' transition will look better

    // default style [above] has the translucent background-color
    // script site-header.js will add class "scrolled" when scrolling down; so, style for rest of the page = opaque
    &.scrolled {
        transition-duration: $top-navbar-transitiontime-in;
        background-color: $site-primarynavbar-background-color;  // opaque
        border-bottom: 1px solid desaturate(darken($site-primarynavbar-background-color, 7%), 9%);
    }
}

.navbar-brand {

    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    // height: $homepage-primarynavbar-height;
    margin: 0 2rem 0 1rem;

    font-size: 1rem;
    padding: 0;
    color: $text-color-nav-default;
    font-weight: $font-weight-semibold;

    .name,
    .role,
    .dingbat {
        flex: 0 0 auto;
    }

    &:hover {
        color: $text-color-nav-default;  // $color-black-lighter3;
    }
}

// style for when an item in the top navbar is given the 'active' class
// by scrollspy
.top-navbar {
//     .navbar-nav {
        .nav-link {
            color: $text-color-nav-default;

            &.active {
                color: $primarynav-link-color-active;
            }
//             &:not(.active):hover {
//                 color: $link-color-highlight;
//             }
        }
//     }
}


//     .navbar-nav .nav-item,
//     .navbar-nav .nav-link + .nav-link {
//     .navbar-nav {
//
        // rules for when the primarynav items are displayed in a row
//         .nav-item {
            // text-align: right;
//
//             margin-right: 1rem;
//
//             &:last-child {
//                 margin-right: -.5rem;
//
// //                 .nav-link {
// //                     padding-right: 0;
// //                 }
//             }
//         }
//         }

// TODO: disabled because it seems that the active closs is alwaysplaced on the -link, not the -item
//     .nav-item {
//          &.active {
//             color: $text-color-primarynav-active;
//             font-weight: $font-weight-semibold;
//         }
//     }

//     .bordering {
//         display: none;
//     }
//     // bit of extra space at bottom of displayed collapse nav
//     .collapsing,
//     .collapse.show {
//         .navbar-nav {
//             margin-bottom: 1.7rem;
//         }
//         .bordering {
//             display: block;
//             border-top: 1px dotted #555;
//         }
//     }


// when top-navbar is horizontal & expanded (i.e. above the navbar-expand-md class), spacing of items displyed in a row
@include media-breakpoint-up(md) {
    .top-navbar {
        .nav-item {
            margin-right: .9rem;

            &:last-child {
                margin-right: .5rem;  // match and subtract the internal padding on the nav item so that rightmost is better symmetrically aligned on the right
            }
        }
    }
}

.navbar-toggler {
    border: unset;
    border-radius: unset;
    padding: 3px 1rem 3px 1rem;
}

// override the bootstrap icon for navigation hamburger glyph
.navbar-toggler-icon {
    background: none;
    width: auto;
    height: auto;
    color: $text-color-nav-default;
    overflow: hidden;  // foundation for setting the icon width to 0 when expanding the sidenav. no effect on normal behavior.

    &::after {
        // position: relative;
        font-family: FontAwesome;
        font-size: $navbar-toggler-icon-font-size;
        text-decoration: none;
        content: "\f0c9"; // fa-reorder
    }
}


@include media-breakpoint-down(xs) {
    .navbar-brand {
        margin-right: 0;
        font-size: .9rem;
    }

    .top-navbar {
        .navbar-toggler-right {
            right: 0;
        }
    }
}

// Medium. 48em
@include media-breakpoint-down(md) {
    .navbar.top-navbar {
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-toggler-icon {
        $navbar-toggler-icon-font-size: 1.9rem;

        &::after {
            font-size: $navbar-toggler-icon-font-size;
        }
    }
}
