// media queries

// *****
// breakpoints refer to the bootstrap4 breakpoint definitions
// incorporated by inclusion of the bootstrap4 mixins/_breakpoints in the site build process

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px
// ) !default;
// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints);


// key names to reference the bootstrap breakpoints
// $key-sm: "sm";   //   sm: 576px
// $key-md: "md";   //   md: 768px
// $key-lg: "lg";   //   lg: 992px
// $key-xl: "xl";   //   xl: 1200px
//
// $breakpoint-small:   map-get($grid-breakpoints, $key-sm);
// $breakpoint-medium:  map-get($grid-breakpoints, $key-md);
// $breakpoint-large:   map-get($grid-breakpoints, $key-lg);
// $breakpoint-xlarge:  map-get($grid-breakpoints, $key-xl);


// example usage
// Medium. Above 48em (768px) */
// @include media-breakpoint-up(md) {
//     text-transform: inherit;
//
// }
// @include media-breakpoint-down(md) {
//     text-transform: inherit;
// }

// x Small. below 34em (544px) */
// @include media-breakpoint-up(xs) {
//     section {
//         // background-color: #FEF4F4;  // red
//     }
// }
//
// @include media-breakpoint-down(sm) {
//     section {
//         background-color: #FFF5F5;  // red
//     }
// }
//
// @include media-breakpoint-up(sm) {
//     section {
//         background-color: #CEC;  // green
//     }
// }
//
// @include media-breakpoint-up(md) {
//
//     section {
//         background-color: #CCE;  // purple
//     }
// }
//
// @include media-breakpoint-up(lg) {
//     section {
//         background-color: #8CC;  // teal
//     }
// }
//
// @include media-breakpoint-up(xl) {
//     section {
//         background-color: #F5F5F5;
//     }
// }
//


// x Small
@include media-breakpoint-down(sm) {
    .container {
        padding-left: 25px;
        padding-right: 25px;
    }
}

// Small.
// $intro-text-shadow-blur-sm:     13px;
// $intro-text-shadow-color-sm:    #EFEFFF;

$pill-element-padding-sm:       1.21rem;
$pill-element-lineheight-sm:    2.5rem;


@include media-breakpoint-down(sm) {

    .theme-table-cell {
        padding-bottom: 3rem;
        padding-top: 4rem;
    }

    #about {
        .theme-table-cell {
            // shift upwards for page spacing on small mobile browsers
            top: -1rem;
        }
    }
    // side spacing
    // #portfolio-title,
    // #portfolio-gallery {
        // padding-left: 15px;
        // padding-right: 15px;
    // }

    #portfolio-gallery {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .theme-section-title {
        font-size: $section-heading-primary-size-small;
        line-height: 1.15;
        letter-spacing: -0.02rem;

        @at-root h2#{&} {
            letter-spacing: -0.03rem;
            word-spacing: 0.09rem;
        }
    }

    .subtitle {
        margin-top: 0;

        font-size: 2rem;
        line-height: 1.4;
    }

    .pill {
        padding-left: $pill-element-padding-sm;
        padding-right: $pill-element-padding-sm;
        line-height: $pill-element-lineheight-sm;
    }

// disabled: because the portfolio download is disabled
//     #portfolio-bottom {
//         padding-top: 0;
//     }

}



$projectName-font-size-md: 2.7rem;
$projectName-font-size-sm: 2.5rem;

// Medium. 48em
@include media-breakpoint-down(md) {

    .background-flame {
        background-image: url($images-path-background + "flame@49@1x.jpg");
    }

    // catch all of the widgets that might come after content and add more space above
    p,
    ul,
    li {
        & + .project-image {
            margin-top: 1.5rem;
        }
    }

    .theme-project {
        .project-header {
            margin-bottom: 1.25rem;

            .company {
                font-size: 1.2rem;
                line-height: 1.4;
            }

            h1 {
                font-size: $projectName-font-size-md;
            }
        }

        .project-overview {

            // reduce the small shift of entire block upwards for better visual alignment of the text with the sidebar rules
            $overview-shift: -7px;
            margin-top: $overview-shift;

            // special case of margin bottom because of the shift up

            font-size: $project-h3-font-size-md;   // TODO: fix this reference somehow
            line-height: 1.4;
            letter-spacing: -0.01rem;
        }
    }

    .project-aside {
        font-size: .91rem;

        aside {
            margin-bottom: 5px;
            padding: 5px 0 5px 0;
        }
        // h4 aka .sectionTitle
        h4 {
           // margin: 0 0 .3rem 0;
           font-size: .96rem;
           // line-height: 1.35rem;
        }
    }
}


@include media-breakpoint-down(sm) {
    .theme-project {
        .project-header {
            h1 {
                font-size: $projectName-font-size-sm;
            }
        }
    }
}



@include media-breakpoint-up(md) {
    // .breakNobreak {
    //     display: inline;
    // }

    .theme-section-title {
        font-size: $section-heading-primary-size-medium;
        word-spacing: 0.27rem;
        line-height: 1.2;

        @at-root h1#{&} {
            letter-spacing: -0.115rem;
        }
        @at-root h2#{&} {
            letter-spacing: -0.029rem;
        }
    }

    .subtitle {
        font-size: 2.5rem;
        line-height: 1.4;
    }
}


// Large. Above 62em (992px)
@include media-breakpoint-up(lg) {

    .theme-section-title {
        font-size: $section-heading-primary-size-large;
        word-spacing: 0.28rem;

        @at-root h1#{&} {
            letter-spacing: -0.115rem;
        }
        @at-root h2#{&} {
            letter-spacing: -0.065rem;
        }
    }

    .subtitle {
        font-size: 3.5rem;
        line-height: 1.4;
    }
}

// Extra large. Above 75em (1200px)
@include media-breakpoint-up(xl) {

    .theme-section-title {
        font-size: $section-heading-primary-size-x-large;
        line-height: 1.15;

        @at-root h1#{&} {
            letter-spacing: -0.18rem
        }

        @at-root h2#{&} {
            letter-spacing: -0.13rem;
        }
    }
}