// WIDGET:
// embedded content
//

$element-sizes: (
    small:  50%,
//     medium: 70%,
//     large:  80%,
    full:   100%,
);

.project-embedded {

    @each $size, $width in ($element-sizes) {
        .element-size-#{$size} {
            width: $width;
        }
    }

//     iframe {
//         width: 100%;
//         height: 100%;
//         @include subtle_border ($default-border-color);
//     }
}

.project-element {
    //
}