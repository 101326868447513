// project-page specific styles
//
// alpine data
// ML iconography redesign gallery

// image notes:
// image grid is 150px x 150px
// max # in a row is 10
// so each grid element is 10%
$project-iconography-image-size: 1500px; // physical dimensions of 1x image
$project-iconography-grid-size: 150px;

// icon-dimension is the size of the visible icon itself, which is smaller than the image's grid dimensions
// so the visible portion of each grid is .76666666
// note that horizontal isnt a problem, but the vertical is
// because i left in the icon labeling text that should not show.
$project-iconography-icon-dimension: 110px;


#iconography-grid {
  .icon-image {
    width: $project-iconography-icon-dimension;
    height: $project-iconography-icon-dimension;

    // image for @1x display
    background-image: url($images-path-portfolio + "alpine/iconography/icon-compilation@1x.png");
    background-size: $project-iconography-image-size;
    background-repeat: no-repeat;
  }
}

// image for @2x display
@include mediaquery-for-image-2x() {
  #iconography-grid {
    .icon-image {
      background-image: url($images-path-portfolio + "alpine/iconography/icon-compilation@2x.png");
    }
  }
}