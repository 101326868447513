// WIDGET:
// image carousel



// carousel overrides
// $carousel-control-color:                   #FFFFFF;
$carousel-control-width:                   15%;
$carousel-control-offset:                  10px;
// $carousel-control-opacity:              .5 !default;
$carousel-control-icon-width:              50px;
$carousel-control-icon-color:              #333333;

$carousel-indicators-z-index:              15; // default BS value
$carousel-indicators-zone-height:          35px;
$carousel-indicators-lineheight:           2px;
$carousel-indicators-background:           #E2E2DF;

$carousel-indicator-width:                 21px;
$carousel-indicator-height:                21px;
$carousel-indicator-margin:                5px;

$carousel-indicator-text-size:             .8rem;
// $carousel-indicator-text-color:            #F0F0F0;
$carousel-indicator-text-color:     $text-color-white-muted;

// $carousel-indicator-bg-color:              $imagewidget-inner-element-bgcolor; //#70A0B0
$carousel-indicator-bg-color:             #006dff;
$carousel-indicator-active-bg:             $imagewidget-inner-element-active-bgcolor;

// $carousel-item-caption-color:              #333333;
$carousel-item-caption-color: $text-color-white-muted;
$carousel-item-caption-background-color: rgba($imagewidget-inner-element-active-bgcolor, .75);

$carousel-transition:                       transform 1s ease-in-out;

// special carousel displays
// $carousel-bg-color-presentation: #777775;
$carousel-bg-color-presentation: #666661;
$carousel-bg-color-presentation: #4e4e49;

// $carousel-bg-color-mobile: #BABAB7;
$carousel-bg-color-mobile: #C5C5BE;

@mixin carousel_control_icon($glyph) {
    &::after {
        content: $glyph;
    }
}



// TODO:  will have to fix these custom sizes so that they behave correctly at smaller page sizes.
// maybe rewrite them to cascade from the column divisions?
// the current sizes are really assuming a context of large page,
// so at small page, the porportions all change. small willwant to be 100% on mobile, eg.

// augment the default BS rule because fluid images inside of flex appear to stretch vertically
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    align-items: flex-start;
}

// custom carousel sizes
// generates a set of rules based on the sizes in the sizes map
$carousel-sizes: (
    // small:  50%,
    // medium: 70%,
    // large:  80%,
    full:   100%,
);

@each $size, $width in ($carousel-sizes) {
    .project-carousel {
        .carousel.carousel-#{$size} {
            width: $width;
        }
    }
}

.project-carousel {
    margin-bottom: 3rem;

    .carousel {
        margin-bottom: 0;
    }

    .carousel-bordered {
        @include subtle_border ($default-border-color);
        .carousel-inner {
            // @include subtle_border ($default-border-color);
            border-bottom: 0;
        }
    }

    // carousel-items are given transparent border so that the following colored borders can be used
    // without the borders changing the proportions of the image,
    // which casuses a visual twitch when the clasees are applied & removed
    // i think this is because the border will add to the image element dimensions

    .carousel-item {
        border: 1px solid transparent;
    }

//     .carousel-item-prev,
//     .carousel-item-next {
//         border-left: 1px solid $color-white-darker5;
//         border-right: 1px solid $color-white-darker5;
//         border-top: 0;
//         border-bottom: 0;
//     }

    // these rules activate the left and right borders on the carousel images
    // making these borders darker so that there is more visual distinction and the switch is visible/noticable
    .carousel-item-left,
    .carousel-item-right {
        border-left-color: $color-white-darker5;
        border-right-color: $color-white-darker5;
    }

}

.project-carousel {

    .carousel-control-prev,
    .carousel-control-next {
        z-index: $carousel-indicators-z-index + 1;
        // display: flex; // 1. allow flex styles
        // align-items: center; // 2. vertically center contents
        // justify-content: center; // 3. horizontally center contents
        flex-direction: column;  // added to center columns in more cases (safari)

        width: $carousel-control-width;

        // Hover/focus state
//         @include hover-focus {
//             color: $carousel-control-color;
//             text-decoration: none;
//             outline: 0;
//             opacity: .9;
//         }
    }
}


    // special visual versions of the carousel
//     .carousel-mobile {
//         background-color: $carousel-bg-color-mobile;
//     }
//     .carousel-item {
//         &.carousel-item-mobile {
//             background-color: $carousel-bg-color-mobile;
//         }
//     }

    .carousel-inner {
        &.presentation {
            background-color: $carousel-bg-color-presentation;
        }

        &.mobile {
            background-color: $carousel-bg-color-mobile;
        }

        &.presentation,
        &.mobile {
            .carousel-item {
                border-color: transparent !important;
            }
        }
    }

    .carousel-item {
        &.presentation-item {
            // background-color: $carousel-bg-color-presentation;
            // padding: 1.5rem 1.5rem 3.1rem 1.5rem;
            padding: 1rem 1rem 2.5rem 1rem;
        }
    }

.carousel-control-prev {
    left: 0;
}
.carousel-control-next {
    right: 0;
}

// icons for previous and next button
.carousel-control-prev-icon,
.carousel-control-next-icon {
    position: absolute;
    bottom: 0;
    display: inline-block;

    width: $carousel-control-icon-width;
    // height: $carousel-indicators-zone-height;

    background-image: none; // turn off the default BS icon
    background-position: initial;

    text-decoration: none;
    color: $carousel-control-icon-color;

    // shared font styles for the icons
    font-family: FontAwesome;
    font-size: 43px;
    line-height: $carousel-indicators-lineheight;
}

.carousel-control-prev-icon {
    left: $carousel-control-offset;

    $glyph: "\f104"; // fa-angle-left
    @include carousel_control_icon($glyph);
}

.carousel-control-next-icon {
    right: $carousel-control-offset;

    $glyph: "\f105"; // fa-angle-right
    @include carousel_control_icon($glyph);
}

// caption for an individual slide
.carousel-caption {
    bottom: 0;
    left: -1px;
    right: -1px;

    padding: 5px 1rem 6px 1rem;

    background-color: $carousel-item-caption-background-color;
    color: $carousel-item-caption-color;
    text-align: left;

    font-size: 0.9rem;
    // .heading, .text {
        // font-size: 0.9rem;
    // }
    .heading {
        font-weight: $font-weight-semibold;
    }

    // p {
    //     font-size: 1rem;
    //     margin-bottom: 0;
    // }
    // .text {
        // margin-bottom: 0;
    // }

}

// indicator pips
.carousel-indicators {
    background-color: $carousel-indicators-background;

    height: $carousel-indicators-zone-height;
    position: relative;
    top: 0;

    align-items: center;
    // Use the .carousel-control's width as margin so we don't overlay those
    //margin-right: auto;
    //margin-left: auto;
    //margin-bottom: 0;
    margin: 0 auto;
    // padding: 5px 0 5px 0;

    li {
        margin: 0 $carousel-indicator-margin;
        padding: 0;
        max-width: $carousel-indicator-width;
        width: $carousel-indicator-width;
        height: $carousel-indicator-height;
        border-radius: 40%;

        color: $carousel-indicator-text-color;
        font-size: $carousel-indicator-text-size;
        line-height: $carousel-indicator-height - 1;
        text-align: center;
        text-indent: 0;
        cursor: pointer;
        background-color: $carousel-indicator-bg-color;
        transition: background-color 0.4s ease;
    }

    .active {
        background-color: $carousel-indicator-active-bg;
    }
}


@include media-breakpoint-down(md) {
    .carousel-control-prev-icon {
        left: 0;
    }
    .carousel-control-next-icon {
        right: 0;
    }
}


