// project-page specific styles
//
// collabnet
// pt redesign
@use "sass:math";

$big-remove-x-font-size: 42vw;

.collabnet-ptRedesign {
  &.remove-mark {
    // z-index: 99;
    position: absolute;
    width: 100%;
    top: 0;
    font-size: $big-remove-x-font-size;

    $fontSizeTemp: math.div($big-remove-x-font-size, 2);
    line-height: floor($fontSizeTemp - 1);

    text-align: center;
    color: #002100;
    opacity: 1;
  }

  // special very unique class
  img {
    &.callout-step-deleted {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
      filter: grayscale(100%);
    }
  }
}