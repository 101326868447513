// **********
// inline-highlight element
//
// <span class="inline-highlight> ... </span>

// intended to mimic the <strong> display,
// but in the font family of the headings.

@use "common/_variables";

.inline-highlight {

    padding: 1px 4px;
    border-radius: 3px;
    background-color: #ebf8b4;

    font-family: variables.$font-family-primary;
    font-weight: variables.$font-weight-semibold;
    font-size: 1rem;
}