// WIDGET:
// the side by side image comparison widget with callouts

// used, for example, in Project Tracker Redesign content

$callout-size:                      20px;
$callout-font-size:                 14px;
$callout-font-weight:               $font-weight-semibold;
$callout-font-color:                $core-white;

$callout-list-reduction-factor:     0.875;
$callout-list-size-reduced:         round($callout-size * $callout-list-reduction-factor);
$callout-list-indent:               27px;

$callout-good-color:                #80AA07;
$callout-good-color-overlay:        rgba( $callout-good-color, .65);
$callout-error-color:               #9B0007;
$callout-error-color-overlay:       rgba( $callout-error-color, .61);
$callout-overlay-border-opacity:    0.80;

$callout-list-heading-bar-radius:   .25rem;

// needs to know the paddings on the columns from bootstrap.
// settings here manually instead of importing the BS file itself
$page-margins-on-container:         15px;

$callouts-z-index:                  0;


@mixin calloutNumberOverlayColor ($colorBackground, $colorBorder) {
    background-color: $colorBackground;
    border: 2px solid rgba($colorBorder, $callout-overlay-border-opacity);
}

.callout-number {

    // use css counters to generate the numbers for each callout dynamically.
    // do not need to add the content to the html now!
    &::before {
        counter-increment: section;
        content: counter(section);
    }

    display: inline-block;
    position: relative;
    width: $callout-size;
    height: $callout-size;
    border-radius: 50%;

    color: $callout-font-color;
    font-size: $callout-font-size;
    font-weight: $font-weight-semibold;
    line-height: $callout-size - 1;
    text-align: center;

    background-color: #696963; // default color if not overridden by a type

    // adjust style when callout numnber appears on the overlay layer
    .callouts-overlay & {
        width: $callout-size - 4;
        height: $callout-size - 4;
        font-size: round($callout-font-size * 0.825);
        line-height: $callout-size - 4.5;
    }
}


.callout-list {
    counter-reset: section;

    .callout-list-heading {
        margin-bottom: 1rem;
        margin-top: 0;

        padding: 1px 1px 2px ($callout-list-indent - 2);
        border-radius: $callout-list-heading-bar-radius;
        font-size: 1rem;
        font-weight: $font-weight-bold;
    }

    dl {
        position: relative;
        margin-left: $callout-list-indent - 2;  // bring the callout numbers slightly closer together
    }

    dt {
        .callout-number {
            position: absolute;
            left: -$callout-list-indent;
            top: 3.5px;  // visual adjustment to align with the text of the list

            // reduce the size ever so slightly when acting as the bullets for the list
            width: $callout-list-size-reduced;
            height: $callout-list-size-reduced;

            font-size: $callout-font-size * $callout-list-reduction-factor;
            line-height: ceil(($callout-size - 0.5 ) * $callout-list-reduction-factor);
        }
    }

    p {
        margin-bottom: .5rem;
    }
}


// types of callouts
.good {
    .callout-number {
        background-color: $callout-good-color;
    }
    .callouts-overlay {
        .callout-number {
            @include calloutNumberOverlayColor ($callout-good-color-overlay, $callout-good-color);
        }
    }
    .callout-list-heading {
        background-color: desaturate(lighten($callout-good-color, 25), 25);
    }
}

.bad {
    .callout-number {
        background-color: $callout-error-color;
    }
    .callouts-overlay {
        .callout-number {
            @include calloutNumberOverlayColor ($callout-error-color-overlay, $callout-error-color);
        }
    }
   .callout-list-heading {
        background-color: desaturate(lighten($callout-error-color, 50), 63);
    }
}

.callouts-side-by-side {
    .info * {
        font-size: 1.0rem !important;
    }
}

.project-callouts-list {

    $callout-list-indent:               30px;
    $callout-list-reduction-factor:     0.80;
    $callout-list-size-reduced:         round($callout-size * $callout-list-reduction-factor);

    padding-top: 1rem;
    padding-bottom: 1rem;

    .callout-list {

        dl {
            margin-left: $callout-list-indent - 1;;
        }

        dt {
            .callout-number {
                left: -$callout-list-indent;
                top: 9px;  // visual adjustment to align with the text of the list

                // TODO DEV: is this rule redundant with one farther above?
                // reduce the size ever so slightly when acting as the bullets for the list
                width: $callout-list-size-reduced;
                height: $callout-list-size-reduced;

                font-size: $callout-font-size * $callout-list-reduction-factor;
                line-height: ceil(($callout-size - 0.25) * $callout-list-reduction-factor);
            }
        }
    }
}


.project-image-with-callouts {
    // TODO: hack fix for when the side-by-side images appear over the side-by-side text on pt redesign
    + .project-image-with-callouts {
        margin-top: -2rem;
    }

    &.container,
    &.container-fluid {
        @include padding-sides-remove;
    }
    .image {
        position: relative;
    }
    .left-image,
    .right-image,
    .single-image {
        margin-bottom: 2rem;
    }

    .callouts-overlay {
        counter-reset: section;

        z-index: $callouts-z-index;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        height: 100%;

        .callout-number {
            position: absolute;
            box-sizing: content-box;
        }
    }
}

// margins above the info text column when in large screen layout
$margins-above-info-md: 1rem;
$margins-above-info-sm: 1rem;

// change default display sequence at smaller window sizes
// from:
// [image A] [image B]
// [text A ] [text B ]
// to:
// [image A]
// [text A ]
// [image B]
// [text B ]

// @media (min-width: 576px) {
@include media-breakpoint-up(md) {

    .project-image-with-callouts {
        .left-image {
            order: 1;
        }
        .right-image {
            order: 3;
        }
        .left-info {
            order: 2;
        }
        .right-info {
            order: 4;
        }

        .left-info,
        .right-info
        .single-info {
            margin-top: $margins-above-info-sm;
        }
    }

    .project-image-with-callouts {
        .left-image,
        .right-image,
        .single-image {
            order: initial;
        }

        .left-info,
        .right-info,
        .single-info {
            order: initial;
            margin-top: $margins-above-info-md;
        }
    }
}

// TODO DEV: fix this media rule
@media (min-width: 992px) {
// @include media-breakpoint-up(lg) {
    .project-image-with-callouts {
        .single-info {
            margin-top: 0;
        }
    }
}
