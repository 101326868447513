// PROJECTS
// styles for the project pages
// and individual projects
//
// table of contents

@use "sass:math";


$project-aside-section-padding-top:         .5rem;  // base margin at top
$project-tableOfContents-padding-top:       $project-aside-section-padding-top + .1rem;  // for the left-side tableOfContents. margin increased to get more precise visual baseline alignment of different font

// $side-item is used for the individual items in the right sidebar (tags, roles, etc) and the left table of contents
$aside-item-line-height:                 1.22rem;
$aside-item-margin-bottom:               .5rem;
$aside-item-heading-lineheight:          1.4rem;



// font size, top level of entries
$tableOfContents-font-size: $font-secondary-scale * 0.96;
// font size, sub entries
$tableOfContents-sublevel-font-size:  decimal-round(($tableOfContents-font-size * $font-secondary-scale), 3);


// the navlink active font becomes bold, which widens it slightly. this is a hacky
// modifier to reduce the font size slightly to make it less wide
$font-size-adjustment-for-active: 98.7%;

// shared mixin for sidebar text
@mixin sidebarText {
    line-height: $aside-item-line-height;
    margin-bottom: $aside-item-margin-bottom;
}


#tableOfContents {
    // background-color: #F9EFEF;

    position: sticky;

    padding: $project-tableOfContents-padding-top 0 1.5rem 0;

    @include section-border-bar;
    // @include section-border-bar("border-bottom");

    font-family: $font-family-secondary;
    font-size: #{$tableOfContents-font-size}rem; // default. TODO: work out font size for smaller screen widths, like the sidebar link text sizes

    .nav-item {
        @include sidebarText;
    }

    // spacing for each top-level .nav-item (FIRST-LEVEL headings)
    > .nav-item {
        margin-bottom: $aside-item-margin-bottom;
    }
// }

// #tableOfContents {

    // FIRST-LEVEL NAV,
    // the main sections (usually h2 headings in the page)
    @at-root nav#{&} {  // = nav#tableOfContents
        flex-direction: column;
    }


    // SECOND-LEVEL NAV,
    // the subsections (usually h3 headings in the page)
    nav {
        &.subnav {
            flex-direction: column;  // TODO DEV: is this necessary? is there a better way to prevent wrap?

            margin-top: .41rem;
            margin-bottom: $aside-item-margin-bottom;  // repeat the item margin bottom for additional visual spacing
            padding-left: 15px;

            // subnavigation text is slightly smaller than the first-level items
            font-size: #{$tableOfContents-sublevel-font-size}rem;
        }
    }

    .nav-link {
        padding: 0;
        transition: color $nav-item-color-transition-duration-long ease;

        &.active {
            transition: color $nav-item-color-transition-duration ease;
            font-size: $font-size-adjustment-for-active;
        }

        &:not(.active):hover {
            transition: color $nav-item-color-transition-duration ease;
        }
    }
}

// intended width of the 'table of contents' section
// this is basically recreating/hardcoding the eventual widths of the .leftColumn section in the layout
// but done this way because this nav is given position: fixed, so physical width needs to be hardcoded
// - $size is keyed to the bootstrap breakpoints
// - $width is measurement of how wide the column ends up being inside fixed width .container
// smaller sizes not needed because the TOC is set to not display at those sizes
// $tableOfContents-widths: (
    // xs:  --px,
    // sm:  --px,
    // md:  150px,
    // lg:  130px,
    // xl:  160px,
// );

// @each $size, $width in ($tableOfContents-widths) {
//     @include media-breakpoint-up($size) {
//         #tableOfContents {
//             width: $width;
//         }
//     }
// }

// bigger
// @include media-breakpoint-up(md) {
//     #tableOfContents {
//         position: sticky;
//         // border-bottom: none;  // turn off border bottom at larger sizes where the nav is in its own column
//     }
// }
