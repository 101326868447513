// ******
// page: about this site

@use "sass:math";

$kingdom-width: 110px;
$kingdom-height: 90px;

$castle-width: 40px;
$castle-height: 40px;

.mykingdom {
    width: $kingdom-width;
    height: $kingdom-height;
    margin: 3rem 0 0 0;
    border-radius: 4px;
    background-color: #222;

    // .castle includes the image already defined in dingbats
    .castle {
        position: relative;
        top: (math.div($kingdom-height - $castle-width, 2)) - 2;

        background-size: $castle-width $castle-height;
        width: $castle-width;
        height: $castle-height;
    }
}

// .grass includes the image already defined in dingbats
// .grass {
//     background-color: #222;
//     border-radius: 4px;
//
//     height: auto;
// }