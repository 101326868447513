// WIDGET:
// 2 images with sliding bar to compare them side by side
// "the before-after" widget
//
@use "sass:math";

$slider-knob-color:         #555555;
$slider-separator-width:    1px;
$slider-separator-color:    $slider-knob-color;

$slider-knob-size:          45px;
$slider-knob-size-active:   35px;
$slider-knob-size-hover:    51px;

// the knob needs to be repositioned so that it is centered on the separator line
// $slider-knob-margin-adjustment:         (($slider-knob-size / 2) - 2);
$slider-knob-margin-adjustment:         (math.div($slider-knob-size, 2) - 2);

// $slider-knob-margin-adjustment-active:  (($slider-knob-size-active / 2) - 2);
$slider-knob-margin-adjustment-active:  (math.div($slider-knob-size-active, 2) - 2);

// $slider-knob-margin-adjustment-hover:   (($slider-knob-size-hover / 2) - 2);
$slider-knob-margin-adjustment-hover:   (math.div($slider-knob-size-hover, 2) - 2);

$slider-knob-shadow-color:              rgba(9, 10, 11, 0.3);
$slider-knob-border-color:              darken($slider-knob-color, 5%);

// font styles control the glyph label on the knob
$slider-knob-glyph:                     "\f07e"; // fa-arrows-h
$slider-knob-fontsize:                  25px;
$slider-knob-fontsize-active:           24px;
$slider-knob-lineheight-active:         $slider-knob-size-active - 1;
$slider-knob-fontsize-hover:            29px;

$callouts-z-index:      9;

// labels (captions) on the individual images
$image-label-text-size-lg:          0.83rem;
$image-label-text-size-sm:          0.72rem;

//$image-label-text-color: #333333;
$image-label-text-color:            $core-white;
$image-label-background-color:      rgba(lighten($imagewidget-inner-element-bgcolor, 13.3333%), 0.61);

$image-label-background-color:      rgba($core-black, 0.6);

$comparison-notch-size-default:     21px;
$comparison-notch-size-lg:          24px;
$comparison-notch-size-sm:          21px;


.image-comparison {
    position: relative;
    overflow: hidden;

    @include subtle_border;

    img {
        width: 100%;
        display: block;
        max-width: none;
    }

    // resize is the image on the left (eg. the 'original')
    .resize {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        overflow: hidden;

        // adding this as defaults to try to get around the 'flash' of the img display at small size
        // before it is given the width by the script.
        img {
            height: 100%;
            width: auto;
        }
    }
}

// info label for the left and right sides
// left side is defined below @ .resize .comparison-image-label
.comparison-image-label {
    position: absolute;

    bottom: 0;
    padding: 0;

    // the notch shape is made with border twisting
    border: $comparison-notch-size-sm solid transparent;

    &.old,
    &.new {
        border-color: transparent;
        border-bottom-color: $image-label-background-color;
    }

    &.old {
        left: 0;
        border-left-color: $image-label-background-color;
    }

    &.new {
        right: 0;
        border-right-color: $image-label-background-color;
    }

    + .label {
        position: absolute;
        bottom: 7px;

        color: $image-label-text-color;
        font-size: $image-label-text-size-sm;
        font-weight: $font-weight-semibold;
        white-space: nowrap;

        &.old {
            left: 5px;
            transform: rotate(45deg);
        }
        &.new {
            right: 4px;
            transform: rotate(-45deg);
        }
    }

}


//
//     .resize {
//
//         // specifics for the in-image caption within .resize
//         .comparison-image-caption {
//             left: -$image-caption-size;
//             right: inherit;
//
//             border-right-color: $image-caption-background-color;
//             border-left-color: transparent;
//
//             transform: rotate(-45deg);
//         }
//     }
//
//
// styles for making the label a cutout shape in corner
//     .comparison-image-label {
//
//
// //         position: absolute;
//
// //         bottom: 0;
// //         left: initial;
// //         right: 0;
//
// //         padding: 0;
//         border-style: solid;
//         border-width: $comparison-notch-size;
//
//         border-color: transparent;
//         border-right-color: $image-label-background-color;
//         border-bottom-color: $image-label-background-color;
//
//     }
//
    // resize is the 'before' or old image
//     .resize {
//
//         // specifics for the in-image caption within .resize
//         .comparison-image-label {
//             left: 0;
//             right: initial;
//             border-color: transparent;
//             border-left-color: $image-label-background-color;
//             border-bottom-color: $image-label-background-color;
//         }
//     }



$handle-animation-duration-hover: 1.2s;
$handle-animation-duration-normal: $handle-animation-duration-hover * 4;

.image-comparison {

    .handle {
        &::after {
            transform-origin: center;
            // animation: name duration timing-function delay iteration-count direction fill-mode play-state;
            animation: knobHover $handle-animation-duration-normal ease-in-out infinite;
        }
    }

    &:hover {
        .handle {
            &::after {
                // transform-origin: center;
                // animation: name duration timing-function delay iteration-count direction fill-mode play-state;
                // animation: knobHover 1.15s ease-in-out infinite;
                // animation-name: knobHover;
                // animation-delay: 0s;
                // animation-iteration-count: infinite;
                // animation-timing-function: ease-in-out;
                animation-duration: $handle-animation-duration-hover;
            }

            // when actively being dragged
            &.ba-draggable::after {
                animation: none;
            }
        }
    }

    .handle {
        z-index: 99;

        // Thin line seperator
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        width: $slider-separator-width;
        margin-left: -(math.div($slider-separator-width, 2));

        background: $slider-separator-color;
        cursor: ew-resize;

        // knob to move separator
        &::after {
            position: absolute;
            top: 49%;
            width: $slider-knob-size;
            height: $slider-knob-size;
            margin-top: -$slider-knob-margin-adjustment;
            margin-left: -$slider-knob-margin-adjustment;

            text-align: center;

            color: $text-color-white-muted;
            font-family: FontAwesome;
            font-size: $slider-knob-fontsize;
            line-height: $slider-knob-size - 1;
            content: $slider-knob-glyph;

            background-color: $slider-knob-color;
            border: 1px solid $slider-knob-border-color;
            border-radius: 50%;
            transition: all 0.21s ease-in-out;
        }

        // change when actively being dragged
        &.ba-draggable::after {
            width: $slider-knob-size-active;
            height: $slider-knob-size-active;
            margin-top: -$slider-knob-margin-adjustment-active;
            margin-left: -$slider-knob-margin-adjustment-active;

            color: $text-color-white;
            line-height: $slider-knob-lineheight-active;
            font-size: $slider-knob-fontsize-active;
        }
    }
}

@keyframes knobHover {
    //     0% {
    //         box-shadow: 1px 1px 2px $slider-knob-shadow-color;
    //     }
    46% {
        transform: scale(1.2);
        //         box-shadow: 5px 5px 2px $slider-knob-shadow-color;
    }

    54% {
        transform: scale(1.2);
        //         box-shadow: 5px 5px 2px $slider-knob-shadow-color;
    }
}


// bigger
@include media-breakpoint-up(md) {

    .comparison-image-label {

        // the notch shape is made with border twisting
        border-width: $comparison-notch-size-lg;

        + .label {
            bottom: 7px;
            font-size: $image-label-text-size-lg;

            &.old {
                left: 6px;
            }
            &.new {
                right: 5px;
            }
        }
    }
}

