// Spinning Icons
// --------------------------

@use "_variables";

.#{variables.$fa-css-prefix}-spin {
          animation: fa-spin 2s infinite linear;
}

// .#{$fa-css-prefix}-pulse {
//   -webkit-animation: fa-spin 1s infinite steps(8);
//           animation: fa-spin 1s infinite steps(8);
// }

@keyframes fa-spin {
  0% {
            transform: rotate(0deg);
  }
  100% {
            transform: rotate(359deg);
  }
}
