// SCSS partial
// COMPONENT = "showcase"
// content-callout is intended to be for portions of the content that should be called-out,
// highlighted, given special attention
//
// **********

// possible background colors of the 'showcase'
$showcase-green:                #D7F3B0;
$showcase-orange:               #FBE1CA;

$showcase-spacing-left-lg:      2rem;
$showcase-spacing-left-sm:      1rem;

$showcase-list-indent:          3.25rem;


.showcase {
    position: relative;
    margin-bottom: 1.5rem;

    // adding extra space above the showcase when it comes right after a heading
    h2 + &,
    h3 + & {
        margin-top: 1.5rem;
    }

    dl {
        dt {
            &.heading {
                margin-bottom: 1.5rem;
            }
        }

        dd {
            margin-bottom: 1.5rem;
        }

        &.numbered {
            counter-reset: dl-numbered;

            dd {
                &::before {
                    // use css counters to generate the numbers for each callout dynamically.
                    // do not need to add the content to the html now
                    counter-increment: dl-numbered;
                    content: counter(dl-numbered)".";
                    margin-right: 2rem;
                }
                margin-bottom: .5rem;
            }
        }
    }

    // coloring
    &.green {
        .showcase-content {
            background-color: $showcase-green;
            border-color: darken($showcase-green, 10%);
        }
    }

    &.orange {
        .showcase-content {
            background-color: $showcase-orange;
            border-color: darken($showcase-orange, 10%);
        }
    }

    // use shape to callout the content, but dont change the content's existing style
    &.inherit {
        .showcase-content {
            font-size: inherit;
        }
    }

    .showcase-content {
        position: relative;
        border-radius: 2px;
        border: 1px solid #EEE;
        background-color: #EFEFE9;  // default color
    }


    .showcase-grid {
        display: grid;

        row-gap: 1rem;
        // column-gap: 1rem;

        // grid-template-columns: auto;

        // child items
        // h2, h3, h4 {
            // grid-column: 1 / 5;
        // }

    }

    .showcase-list {
        counter-reset: dl-numbered;

        div {
            padding-top: 0;
            margin-left: $showcase-list-indent;

            &::before {
                position: absolute;
                // left: $showcase-spacing-left-lg;  // default.
                counter-increment: dl-numbered;
                content: counter(dl-numbered)".";

            }
        }

        // .listNumber {
            // counter-increment: dl-numbered;
            // content: counter(dl-numbered)".";

            // text-align: right;
        // }

    }


    // .showcase-caption {}  // captions use the site-wide caption rules

    // remove heading margins inside of showcase
    h2, h3, h4,
    .h3, .h4 {
        margin-top: 0;
        margin-bottom: 0;

        line-height: 1.0lh;

        & + div {
           padding: 0;
        }
    }
}


// TODO: change breakpoints to be sized by default, and then changed for larger?
// smallest, and up
@include media-breakpoint-up(xs) {
    .showcase {

        // adjust let spacing and offset for the list bullets
        .showcase-content {
            padding: 1rem 1rem 1rem $showcase-spacing-left-sm;
            font-size: 1.25rem;
        }
        .showcase-list {
            div {
                &::before {
                    left: $showcase-spacing-left-sm;
                }
            }
        }
    }
}

// medium, and up
@include media-breakpoint-up(md) {
    .showcase {
        .showcase-content {
            padding: 1.5rem 2rem 2rem $showcase-spacing-left-lg;
            font-size: 1.55rem;
        }
        .showcase-list {
            div {
                &::before {
                    left: $showcase-spacing-left-lg;
                }
            }
        }

    }
}
