// WIDGET:
// a grid of images organized by isotope
//
// e.g. used on the autosave project page.

$grid-item-sizes: (
    1: 25%,
    2: 50%,
    3: 75%,
    4: 100%,
);

$isotope-grid-margin-side: 10px;

.isotope-grid {

    // negative left and right margins in order to offset the margins on each item displayed
    margin-left: -$isotope-grid-margin-side;
    margin-right: -$isotope-grid-margin-side;

    .grid-item {
        margin-bottom: 3px;
        padding: $isotope-grid-margin-side $isotope-grid-margin-side 5px $isotope-grid-margin-side;
    }

    @each $size, $width in ($grid-item-sizes) {
        .grid-item-width#{$size} {
            width: $width;
        }
    }

}