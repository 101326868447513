//
// site homepage
//

@use "sass:math";

// shadow: offset-x | offset-y | blur-radius
$intro-text-shadow-offset-x: 0;
$intro-text-shadow-offset-y: 0;
// $intro-text-shadow-blur:     15px;
$intro-text-shadow-blur:  3px;
$intro-text-shadow-color: rgba(#EFEFFF, 0.50);

$intro-background-fadein-duration:  2.11s;
$intro-background-fadein-delay:     0s;

$asterisk-span-animation-time: 11s;
$asterisk-span-delay-time: 3.9s;
// $asterisk-color: #7799F2;
$asterisk-color: #91ACF2;
$asterisk-color-brighter: #A0C1F9;

// @mixin theme-section-title-text ($fontsize: $section-heading-primary-size-x-large) {
//     font-size: $fontsize;
//     line-height: 1.13em;
//     letter-spacing: -0.026em;
//     word-spacing: 0.04em;
// }


// =============
// intro section

.background-flame {
    background-image: url($images-path-background + "flame@49@1x.jpg");
}

// image for @2x display
@include mediaquery-for-image-2x() {
    .background-flame {
        background-image: url($images-path-background + "flame@39@2x.jpg");
    }
}

// browser hacks to fix image twitching
// edge
@supports (-ms-accelerator:true) {
    .background-flame {
        background-attachment: scroll;
    }
}
@supports (-ms-ime-align:auto) {
    .background-flame {
        background-attachment: scroll;
    }
}
// ie 10/11*
// @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
//     .background-flame {
//         background-attachment: scroll;
//     }
// }

#intro {

    #intro-container {
        position: relative;
        top: -10%;
    }

    h1 {
        font-weight: $font-weight-bold;
        color: #111;
        // shadow behind text for readibility
        text-shadow: $intro-text-shadow-offset-x $intro-text-shadow-offset-y $intro-text-shadow-blur $intro-text-shadow-color;
        backface-visibility: hidden;
    }

    #intro-name {
        margin-bottom: 1rem;
    }
    // .backcell is a full window div element that covers the entire background/section
    // on initial load, .backcell is white and opaque;
    // once animation commences, it fades out completely,
    // revealing the background image.
    // this is, i think, cheaper performance-wise than trying to fade the background image itself.
    .backcell {
        position: absolute;
        background-color: #FFFFFF;
        width: 100%;
        height: 100vh;

        // initial class on backcell to define the fade-out animation
        &.animated {
            // animation: name   duration                   timing-function    delay                        iteration-count direction fill-mode play-state;
            animation: introFade $intro-background-fadein-duration ease-in-out $intro-background-fadein-delay 1 normal forwards;
            // animation-name: introFade;
            // animation-delay: .3s;
            // animation-iteration-count: 1;
            // animation-timing-function: ease;
            // animation-duration: 2.4s;
            // animation-fill-mode: forwards;
        }
        // class added when animation is done
        // to remove the backcell from display, in a cheap attempt to improve subsequent browser performance.
        &.animationDone {
            display: none;
        }
    }
}

// @include media-breakpoint-down(sm) {
    // #intro {

        // $intro-text-shadow-blur-sm:     13px;
        // $intro-text-shadow-color-sm:    #EFEFFF;

        // h1 {
        //     // shadow behind text for readibility
        //     text-shadow: $intro-text-shadow-offset-x $intro-text-shadow-offset-y $intro-text-shadow-blur-sm $intro-text-shadow-color-sm;
        // }
    // }
// }


@keyframes introFade {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

// =============
// ABOUT

.background-blue {
    background-color: $about-background-color;
}

#about {
    color: $primary-text-color-white;

    h2 {
        margin: 0 0 5.5rem 0;
        font-weight: $font-weight-normal;
    }
}

.subtitle {
    font-weight: $font-weight-normal;
}

// =============
// popover in about section 'tinkerer'

// overriding/extending the default BS popover style
// to create a black translucent popover
// scss modifies/extends bootstrap/scss/_popover.scss as needed. mostly modify coloring

// $popover-inner-padding:         5px 5px 10px 5px;
$popover-inner-padding: 1rem;
$popover-border-radius:         3px;

$popover-color-base:            #242424;
$popover-background-opacity:    .89;
$popover-border-opacity:        .90;

$popover-background:            rgba($popover-color-base, $popover-background-opacity);
$popover-border-color:          rgba($popover-color-base, $popover-border-opacity);

.popover-tinkerer {
    padding: $popover-inner-padding;
    background-color: $popover-background;
    border: none;
    border-radius: $popover-border-radius;
    max-width: 350px;
}

.popover-tinkerer {
    $popover-arrow-width:           20px;
    $popover-arrow-height:          16px;
    $popover-border-width:          1px;  // border is 0, but set to 1px to fudge the layout
    $popover-arrow-color:           $popover-background;
    $popover-arrow-outer-color:     transparent;

    .arrow {
        width: $popover-arrow-width;
        height: $popover-arrow-height;
    }

    // Popover directions
    // these are all overrides of bootstrap

    &.bs-popover-top {
        // margin-bottom: $popover-arrow-height;

        .arrow {
            bottom: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);  // BS4

            &::before,
            &::after {
                $tempWidth: math.div($popover-arrow-width, 2);
                border-width: $popover-arrow-height $tempWidth 0;  // BS4
            }

            &::before {
                border-top-color: $popover-arrow-outer-color;
            }
            &::after {
                border-top-color: $popover-arrow-color;
            }
        }
    }

    &.bs-popover-right {
        // margin-left: $popover-arrow-height;

        .arrow {
            left: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
            width: $popover-arrow-height;
            height: $popover-arrow-width;

            &::before,
            &::after {
                $tempWidth: math.div($popover-arrow-width, 2);
                border-width: $tempWidth $popover-arrow-height $tempWidth 0;  // BS4
            }

            &::before {
                border-right-color: $popover-arrow-outer-color;
            }
            &::after {
                border-right-color: $popover-arrow-color;
            }
        }
    }

    &.bs-popover-bottom {
        // margin-top: $popover-arrow-height;

        .arrow {
            top: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);

            &::before,
            &::after {
                $tempWidth: math.div($popover-arrow-width, 2);
                border-width: 0 $tempWidth $popover-arrow-height $tempWidth;  // BS4
            }

            &::before {
                border-bottom-color: $popover-arrow-outer-color;
            }
            &::after {
                border-bottom-color: $popover-arrow-color;
            }
        }

        // This to remove the popover-header's border just below the arrow
        .popover-header::before {
            // position: absolute;
            // top: 0;
            // left: 50%;
            width: $popover-arrow-width;

            margin-left: math.div($popover-arrow-width, -2);
            border-bottom: $popover-border-width solid $popover-header-bg;
        }
    }

    &.bs-popover-left {
        // margin-right: $popover-arrow-height;

        .arrow {
            right: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
            width: $popover-arrow-height;
            height: $popover-arrow-width;

            &::before,
            &::after {
                $tempWidth: math.div($popover-arrow-width, 2);
                border-width: $tempWidth 0 $tempWidth $popover-arrow-height;  // BS4
            }

            &::before {
                border-left-color: $popover-arrow-outer-color;
            }
            &::after {
                border-left-color: $popover-arrow-color;
            }
        }
    }

    &.bs-popover-auto {
        &[x-placement^="top"] {
            @extend .bs-popover-top;
        }
        &[x-placement^="right"] {
            @extend .bs-popover-right;
        }
        &[x-placement^="bottom"] {
            @extend .bs-popover-bottom;
        }
        &[x-placement^="left"] {
            @extend .bs-popover-left;
        }
    }
}

// contents of the tinkerer popup
.specialPopup {
    font-size: 1.1rem;
    font-family: $font-family-dictionary;
    letter-spacing: 0.03rem;
    color: $text-color-white;

    h2 {
        font-size: 1.42rem;
        margin: .6rem 0 0rem 0;
        letter-spacing: 0.02rem;
    }
    .word {
        margin-bottom: 1.3rem;
    }
    p {
        margin: 0;
    }
    .partofspeech {
        font-style: italic;
    }
    .hilight {
        color: #55EE11;
    }
}

.specialPopupTrigger {
    cursor: help;
    display: inline-block;
    white-space: nowrap;

    .fa {
        position: relative;
        top: -0.8em;
        left: -0.45em;
        font-size: 0.57em;  // leave as em: proportional to the parent title text size
        // color is tuned specifically to the blue background
        // color: #6083F6;  // darker
        color: $asterisk-color;
    }
}

.fa-asterisk-spin {
    // animation: name duration timing-function delay iteration-count direction fill-mode play-state;
    animation: fa-asterisk-spin $asterisk-span-animation-time cubic-bezier(0.34, 0.09, 0.44, 0.95) $asterisk-span-delay-time infinite;
}

@keyframes fa-asterisk-spin {
    0% {
        transform: rotate(0deg);
    }
    27% {
        transform: rotate(720deg);
    }
    27.001% {
        transform: none;
        color: $asterisk-color-brighter;
    }
}


// =============
// PORTFOLIO

// .background-sand {
    //  background-image: url($images-path-background + "portfolio-sand-75percent.jpg"); */
//     background-color: #444;
// }

.background-portfolio {
    background-color: #EEEEEC;
}

// fix to make the anchor scrolling look better
// #portfolio {
//     @include scrollFix-content-before (55px, 0);
// }

#portfolio-title {
    padding-top: 4.5rem;
    padding-bottom: 0;  // padding:0 to try to making spacing between h2 & subtitle match that in #about section

    h2 {
        margin-top: 0;
        margin-bottom: 3rem;
        color: $primary-text-color-dark;
        font-weight: $font-weight-normal;
    }
}


#portfolio-gallery {
    padding-top: 0;
    padding-bottom: 5rem;

    .subtitle {
        margin-bottom: 2rem;
    }
}

// =============
// CONTACT

// all cases
#contact {
    background-color: $social-background-color;

    // basic case
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
}

.social {
    flex-wrap: wrap;

    a {
        text-align: center;
        color: $core-white;

        flex: 0 1 33%;
        margin: 0;
        font-size: 3.25rem;

        span {
            opacity: 0.61;
            transition: opacity 0.5s ease, transform 1.8s ease;
            backface-visibility: hidden;
        }
        &:hover {
            span {
                opacity: 1;
                transform: scale(1.2, 1.2);
                transition: opacity 0.4s ease, transform 0.35s ease;
            }
        }
        .fa-gitlab {
            font-size: .91em;  // default size appears a little bigger than the others. this normalizes it.
        }
    }
}



// =============
// FOOTER

footer {
    padding: 1rem 0 2.6rem 0;
    background-color: $footer-background-color;
    color: $text-color-white-muted;

    font-family: $font-family-secondary;
    font-size: 0.91rem;

    .aboutSite-link {
        margin-left: .5rem;
    }
}


// small. also applies when iphone is in landscape orientation
@include media-breakpoint-up(sm) {
    .social {
        a {
            flex-basis: 17%;
        }
    }
}


// medium, and up
@include media-breakpoint-up(md) {
    #contact {
        padding-top: 3.9rem;
        padding-bottom: 3.9rem;
    }

    .social {
        a {
            flex-basis: auto;
            margin: 0 1.5rem;
            font-size: 3.7rem;
        }
    }

    footer {
        font-size: 1rem;
    }
}

// large, and up
@include media-breakpoint-up(lg) {
    #contact {
        padding-top: 4.4rem;
        padding-bottom: 4.6rem;
    }

    .social {
        a {
            flex-basis: 8%;
            font-size: 4.1rem;
        }
    }
}
