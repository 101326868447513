// visual design styles for the sidenav side panel
//
// .sidenav styles add to .sidePanelCollapse styles for site-specific design.
// one could also override .sidePanelCollapse here


$sidenav-side:                      "right";  // the default position = right side of window

$sidenav-zindex:                    $zindex-fixed + 1;  // z-index of Bootstrap4 default nav, plus 1, to put the sidenav above the nav

$sidenav-width-large:               260px;
$sidenav-width-medium:              $sidenav-width-large - 20;

$sidenav-background-color:                  #062B62;
$sidenav-projectsection-background-color:   adjust_hue(desaturate(lighten($sidenav-background-color, 3), 2), -1.1);

// $sidenav-projectsection-background-color-top:       adjust_hue(desaturate(lighten($sidenav-background-color, 2.7), 2), -1.1);
// $sidenav-projectsection-background-color-bottom:    adjust_hue(desaturate(lighten($sidenav-background-color, 2.7), 2), -1.1);
// $gradient-angle: 5deg;
// $sidenav-projectsection-background-gradient: linear-gradient($gradient-angle, $sidenav-projectsection-background-color-top, $sidenav-projectsection-background-color-bottom);

$sidenav-headerBar-color:              #001B4E;

$sidenav-closeButton-height:        36px;
$closeicon-xposition-large:         193px;
$closeicon-xposition-medium:        $closeicon-xposition-large - 5px;  // position when sidenav is smaller

$sidenav-list-space-left:           25px;
$sidenav-list-space-left-medium:    20px;
$sidenav-list-space-right:          1.5rem;

$sidenav-sectionFlame-color:        lighten($sidenav-projectsection-background-color, 3%);
$flame-top-height:                  30px;
$flame-bottom-height:               100px;


$projectlist-link-hover-color:              $link-color-highlight;

$projectlist-item-font-size-default:        0.83rem;
$projectlist-item-font-size-md:             decimal-round(($projectlist-item-font-size-default * 0.96), 3);

$projectlist-company-font-color:            #556B7A;
// $projectlist-company-font-color-hover:      #3D88C9;
$projectlist-company-font-color-hover: #4E7B63;



// $nav-item-color-transition-duration:        0.3s;
$company-color-transition-duration:         0.7s;



.sidenav {
    z-index: $sidenav-zindex;
    position: fixed;

    top: 0;
    bottom: 0;
    #{$sidenav-side}: 0;
    height: 100vh;

    padding: 0;
    overflow-x: hidden;

    background-color: $sidenav-background-color;

    // because the width of the top-level .sidepanel is changed during the collapse event by Bootstrap,
    // an interior element is used to maintain a fixed width for the content.
    .sidenav-content {
        position: relative;
        width: $sidenav-width-medium;
    }

    // sidenav is structured to be one general "nav" element
    .nav {
        display: block;  // override bootstrap
        margin-left: $sidenav-list-space-left-medium;  // left-side margin from the edge of sidenav
    }

    .navbar-nav {
        display: block;  // override bootstrap
    }

    .nav-item {
        a {
            &:hover {
                color: $projectlist-link-hover-color;
                transition-duration: $nav-item-color-transition-duration;
            }
            transition-duration: $nav-item-color-transition-duration-long;
        }
    }

    .nav-link {
        margin-bottom: .5rem;
        padding-bottom: .5rem;
    }

    // header bar at top of the sidenav. visually extends the horizontal primary nav bar,
    // and contains the "X" close icon
    .sidenav-headBar {
        z-index: $sidenav-zindex;
        position: fixed;
        // top: 0;
        width: $sidenav-width-medium;
        height: $project-primarynavbar-height;

        // flex layout model to match the primary navbar
        display: flex;
        align-items: center;

        background-color: $sidenav-headerBar-color;
    }

    .sidePanel-close {
        // visually adjust the close icon to the right to match the position of the hamburger menu in the primary nav
        margin-left: $closeicon-xposition-medium;
    }

    .sidenav-list {
        margin-top: ($project-primarynavbar-height + 10);
        padding: 0 0 3rem 0;
        font-family: $font-family-secondary;
        letter-spacing: 0.02rem;
    }

    // the projects section of the sidenav, including the 'flame' top and bottom pieces
    // distinguished visually with the fancyFlame graphics in the background
    .sectionFlame {
        position: relative;  // this is here because of the little fancy flame that is added

        // do this brittle adjusting so that the section element can have a different background color than the sidenav itself
        margin-left: -$sidenav-list-space-left;
        padding-left: $sidenav-list-space-left;
        padding-right: $sidenav-list-space-right;

        background-color: $sidenav-projectsection-background-color;
        transition: background $nav-item-color-transition-duration-long linear;

        &:hover {
            transition: background $nav-item-color-transition-duration-long linear;
            background-color: $sidenav-sectionFlame-color;

            svg {
                fill: $sidenav-sectionFlame-color;
                transition: fill $nav-item-color-transition-duration-long linear;
            }
        }
    }
}

// style for when an item in the sidebar is given the 'active' class
// by scrollspy
.sidenav {
    .nav-link {
        &.active {
            color: $sidenav-link-color-active;
        }
//         &:not(.active):hover {
//             color: $link-color-highlight;
//         }
    }
}


// rules for the flame graphics that call-out the projects section
// general styles. applied to both top and bottom of the flame
.fancyFlame {
    z-index: -1;  // fix so that the links in same area can be clicked
    position: absolute;
    left: 0;
    right: 0;

    svg {
        display: block;
        fill: $sidenav-projectsection-background-color;
        transition: fill $nav-item-color-transition-duration-long linear;
    }
}

#flameTOP {
    height: $flame-top-height;
    top: -$flame-top-height;
    svg {
        position: absolute;
        bottom: 0;
    }
}

#flameBOTTOM {
    height: $flame-bottom-height;
    bottom: -$flame-bottom-height;
}

.projectlist {
    // bottom spacing can be shortened slightly when the flame-like image is displayed
    // margin-bottom: 2rem;
    margin-bottom: 2.5rem;
    padding-bottom: 0;

    font-size: $projectlist-item-font-size-default;
    line-height: 1.35;
}

// group = company name + projects
.projectlist-group {
    margin-bottom: .9rem;

    // small extra space at top of list for separation
    &:first-child {
        margin-top: .4rem;
    }

    &:hover {
        .projectlist-company {
            color: $projectlist-company-font-color-hover;
        }
    }
}

// company name
.projectlist-company {
    margin-bottom: 0;
    padding: .37rem 0;

    font-size: 0.8rem;
    font-weight: $font-weight-bold;
    letter-spacing: 0.03rem;

    color: $projectlist-company-font-color;
    transition: color $company-color-transition-duration;
}

// item = project name
.projectlist-item {

    display: block;
    padding: .31rem 1rem .35rem 0rem;
    font-weight: $font-weight-normal;

    // disabled class = current = used for current location
    &.disabled {
        color: $core-white;
        font-weight: $font-weight-bold;
        letter-spacing: 0.04rem;
        word-spacing: 0.02rem;
    }
}

// overrides for display on the homepage
.theme-homepage {
    .sidenav {

        .nav {
            margin-left: $sidenav-list-space-left;  // left-side margin from the edge of sidenav
        }

        .sidenav-headBar {
            height: $homepage-primarynavbar-height;
        }

        .sidePanel-close {
            // visually adjust the close icon to the right to match the position of the hamburger menu in the primary nav
            height: $homepage-primarynavbar-height;
        }

        .sidenav-list {
            margin-top: ($homepage-primarynavbar-height + 20);
            font-size: 1rem;
        }
    }
}


// sidenav for smaller screens
@include media-breakpoint-down(md) {

    .sidenav {

//         .nav {
//             margin-left: $sidenav-list-space-left-medium;  // left-side margin from the edge of sidenav
//         }

        .nav-link {
            margin-bottom: .25rem;
            padding-bottom: .35rem;
        }

        .sidenav-list {
            padding: 0 0 3.5rem 0;
            font-size: .9rem;
            letter-spacing: normal;
        }

    }

    // projectlist is the section listing out the project pages/links
    .projectlist {
        font-size: $projectlist-item-font-size-md;
    }
}


// for larger windows
@include media-breakpoint-up(md) {

    .sidenav {

        .nav {
            margin-left: $sidenav-list-space-left;  // left-side margin from the edge of sidenav
        }

        .sidenav-headBar {
            width: $sidenav-width-large;
        }

        .sidePanel-close {
            // visually adjust the close button to match the x-position of the hamburger menu button in the primary nav
            margin-left: $closeicon-xposition-large;
        }

        .sidenav-content {
            width: $sidenav-width-large;
        }
    }

}