// scss aggregration file
// for font of the main body content of the stories/projects

// working on serif
// @mixin text-font-serif {
//     font-family: $font-family-serif;
//     font-size: 14.8px;
//     line-height: 1.75;
// }


// section {
main section {
    > p,
    > ol,
    > ul {
        font-family: $font-family-serif;
        font-size: .925rem;
        line-height: 1.8;
    }
}
