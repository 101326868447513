// widen style
//
// sizes for making the element 'widen' outside of the current container

@use "sass:math";

// $widen-margin-unit: 2rem;
// $widen-margin-unit: percentage(1 / $grid-columns);
$widen-margin-unit: percentage(math.div(1, $grid-columns));

$widen-sizes: (
    1: 1,
    2: 2,
    3: 3,
//    4: 4,
//    5: 5,
);

@include media-breakpoint-up(md) {

    @each $index, $width in ($widen-sizes) {
        .widen-#{$index} {
            $margin-to-remove: $width * $widen-margin-unit;
            margin-left: -$margin-to-remove;
            margin-right: -$margin-to-remove;
        }
    }

    @each $index, $width in ($widen-sizes) {
        $margin-to-remove: $width * $widen-margin-unit;
        .extendleft-#{$index} {
            margin-left: -$margin-to-remove;
        }
        .extendright-#{$index} {
            margin-right: -$margin-to-remove;
        }
    }

}

// @include media-breakpoint-down(sm) {
//
//     @each $index, $width in ($widen-sizes) {
//         .widen-#{$index} {
//             margin-left: inherit;
//             margin-right: inherit;
//         }
//     }
// }


// @media (max-width: 767px) {
//     @each $index, $width in ($widen-sizes) {
//
//         .extendleft-#{$index} {
//             margin-left: inherit;
//         }
//         .extendright-#{$index} {
//             margin-right: inherit;
//         }
//     }
// }
