// ------- PORTFOLIO -------*/

@use "sass:math";

// @function side($height, $angle: 45deg) {

//     $unit: unit($height);
//     $height: strip-unit($height);

//     $square: ($height * $height) / 8;  // = h^2 / 8
//     $h: sqrt($square);
//     $h: decimal-floor($h, 2);  // uses site function
//     @return unquote($h + $unit);
// }


$gallery-image-max-height: 160px;
// $gallery-image-max-height-xs: 200px;  // @breakpoint x-small
// $gallery-image-max-height-sm: 210px;  // @breakpoint sm
$gallery-image-max-height-md: 140px;  // @breakpoint md
$gallery-image-max-height-lg: 150px;  // @breakpoint lg
$gallery-image-max-height-xl: 160px;  // @breakpoint xl


$portfolio-item-text-color: $core-black;
$gutter: $grid-gutter-width;

$portfolio-item-side-padding:           10px;
$portfolio-item-topbottom-margin:       25px;

$portfolio-item-inside-margin-side:     30px;
$portfolio-item-inside-margin-top:      25px;
// $portfolio-item-inside-margin-bottom:   25px;
$portfolio-item-inside-margin-bottom: 1.6rem;

$portfolio-item-content-space-side:     20px;


// $portfolio-card-background-color: #DEDEDA;
// $portfolio-card-background-color-default:           #DBDBD7;
$portfolio-card-background-color-default: #F4F4F1;

$portfolio-card-background-color-light:             lighten($portfolio-card-background-color-default, 11%);   // hovering
$portfolio-card-background-color-dark:              darken($portfolio-card-background-color-default, 16.9%);     // not-hovering


$portfolio-card-border-radius:      4px;
$card-image-border-radius:          $portfolio-card-border-radius;

$card-image-border-color:           #C1C1BF;
$card-image-border-color-light:     lighten($card-image-border-color, 10%);
// $card-image-background-color:       #111;


$action-button-background-color:    darken($about-background-color, 6.6667%);
$action-button-border-color:        darken($action-button-background-color, 6.6667%);



// timing and definitions for the hovers/not-hovers
//
// $portfolio-card-hover-opacity:          0.17;
// $portfolio-card-hover-opacity-inverse:  .82;

// $card-image-nothover-opacity:           .79;
// $card-image-nothover-opacity-inverse:   .3;

// $card-block-nothover-opacity:           0.67;

// $card-arrow-hover-opacity:              1;

// use the inverse because the card overlay is whitebackground on top of the image
// $image-overlay-background-color-default:    rgba($core-white, 0);
// $image-overlay-background-color-default:    $core-white;
// $image-overlay-background-color-hover:      rgba($color-white-darker1, $portfolio-card-hover-opacity-inverse);
// $image-overlay-background-color-hover:      $core-white;
// $image-overlay-background-color-nothover:   rgba($color-black-lighter1, $card-image-nothover-opacity-inverse);
// $image-overlay-background-color-nothover:   $color-black-lighter1;

$image-overlay-background-color-light:      $core-white;
// $image-overlay-background-color-dark:       $color-black-lighter1;
$image-overlay-background-color-dark: #111;

$image-overlay-nothover-opacity:    0.3;
$image-overlay-default-opacity:     0.2;


//
// normal/default
// $card-image-background-default-transition-duration: 0.25s;  // fast
// $card-image-default-transition-duration:            0.9s;   // slow

// hovering
// $card-hover-transition-duration-default:            0.25s;  // fast
$card-hover-transition-function-default:            ease;
$image-overlay-hover-transition-duration:           0.125s; // fast/2

// not-hovering
// $card-image-nothover-transition-duration:           0.9s;


$duration-fast:       0.25s;
$duration-med:        0.4s;
$duration-slow:       0.8s;
$duration-slower:     1.1s;
$duration-very-slow:  1.7s;
$duration-long:       1.8s;
// $duration-longest:    3s;


$image-overlay-duration-default:    0.15s;
$image-overlay-duration-fadeIn:     0.25s;
$image-overlay-duration-fadeOut:    0.25s;

$card-overlay-light-duration-default:    0.25s;
$card-overlay-dark-duration-default:     0.25s;


    // bootstrap overrides of item margins only in portfolio to reduce default 15px space from between items
    // note: these values have to coordinate with the padding on the portfolio-item cards to maintain the overall row margins
//     .row {
//         margin-left: -10px;
//         margin-right: -10px;
//     }


#portfolio-grid {
    margin-left: -$portfolio-item-side-padding;
    margin-right: -$portfolio-item-side-padding;

    div {
            // border: 1px solid #DBB;
    }
}

.portfolio-item {

    // this is here in case i implement some other manipulation later
    // position: relative;

    // padding and margins that affect the overall grid layout of the set of cards
    margin: 0 0 $portfolio-item-topbottom-margin 0;

    // override the default BS side padding (15px)
    // this override also requires adjusting the containing row
    padding-right: $portfolio-item-side-padding;
    padding-left: $portfolio-item-side-padding;

    // overrides of bootstrap .card
    .card {

        padding-bottom: $portfolio-item-inside-margin-bottom;

        // border: 1px solid $card-image-border-color;
        border-width: 1px;
        border-style: solid;
        /* top | right | bottom | left */
        border-color: $card-image-border-color-light $card-image-border-color $card-image-border-color $card-image-border-color-light;
        border-radius: $portfolio-card-border-radius;

        color: $portfolio-item-text-color;

        &.state-default {
            // background-color: $portfolio-card-background-color-default;
        }


        // transition-property: background-color;
        // transition-duration: ($duration-fast / 2);  // cut in half because the color difference in background-color between normal and hovered is very small
        // transition-timing-function: $card-hover-default-transition-function;

        // link element is the primary container so that the entire card is clickable
        // a {
        //     padding: $portfolio-item-inside-margin-top $portfolio-item-inside-margin-side $portfolio-item-inside-margin-bottom $portfolio-item-inside-margin-side;
        // }

        &:hover {
//                 animation-name: cardbeat;
//                 animation-duration: 2s;
//                 animation-iteration-count: infinite;
//                 animation-timing-function: ease-in-out;
//                 animation-delay: 0.5s;

            // background-color: $portfolio-card-background-color-hover;

            // background-color: #DCC;
            // .card-overlay {
                // &.light {
                //     visibility: visible;
                //     opacity: 1;  // turn on visibility when hovering
                // }
                // &.dark {
                    // visibility: hidden;  // turn off visibility when hovering in attempt to optimize. can't use display: none
                // }
            // }


            .image-overlay {
                opacity: 0;
                &.light {
                        // opacity: $portfolio-card-hover-opacity-inverse;
                        transition-duration: $duration-fast;
                    }
                    &.dark {
                    //    opacity: 0;
                        // opacity: $image-overlay-default-opacity;
                    }

                // transition: opacity $image-overlay-hover-transition-duration $card-hover-transition-function;
                // transition: background-color $image-overlay-hover-transition-duration $card-hover-transition-function;
            }

            .card-image {
                outline: 1px solid #999;

            }

        }

        // when hovering over one of the cards, the other cards are in a "not hoevered" state.
        // change display of the other cards that are not hovered over.
        // intent is to focus on the current item

        &.state-nothovered {
            // background-color: $portfolio-card-background-color-nothover;  // darken the background color of the card = the area around the image
            transition-duration: $duration-slow;

            // .card-overlay {
                // &.light {
                //    visibility: hidden;
                // }
                // &.dark {
                //     visibility: visible;
                //     opacity: 1;
                //     transition-duration: $duration-slower;   // delay when changing to this state
                // }
            // }

            // .image-overlay {
                //&.light {
                //    opacity: 0;
                //}
                // &.dark {
                //     opacity: $image-overlay-nothover-opacity;
                //     transition-duration: ($duration-very-slow * .75);  // delay when changing to this state
                // }
                // opacity: $image-overlay-default-opacity;
                // transition-duration: $duration-slower; // delay when changing to this state
            // }

        }


       // container for the gallery image
        .card-image {
            // position: relative so that child elements can have correct absolute position
            position: relative;
            overflow: hidden;
            height: $gallery-image-max-height;

            margin-bottom: 1rem;
            margin-top: 1rem;
            margin-left: $portfolio-item-content-space-side;
            margin-right: $portfolio-item-content-space-side;

            // border: 1px solid $card-image-border-color;

            // border radius syntax sequence: top-left top-right bottom-right bottom-left
            // border-radius: $card-image-border-radius $card-image-border-radius math.div($card-image-border-radius, 2) math.div($card-image-border-radius, 2);
            // border-radius: $card-image-border-radius $card-image-border-radius 0 0;
            border-radius: $card-image-border-radius;

            outline: 1px solid #EEE;
            // transition-property: background-color;
            // transition-duration: $card-image-background-default-transition-duration;
            // transition-timing-function: $card-hover-default-transition-function;
            // transition: none;
        }

        img {
            // position: relative;
            display: block;
            width: 100%;

            //transition-timing-function: $card-hover-transition-function;
            //transition-duration: $card-image-default-transition-duration;
            //transition-property: opacity;
            // transition: unset;

            // hack to fix wiggle glitch of the img on hover
            backface-visibility: hidden;
        }

    }

    .card-text {
        // expand as a container in the .card
        flex: 1;

        // and also display as flex internally
        display: flex;
        flex-direction: column;

        margin: 0 $portfolio-item-content-space-side 0 $portfolio-item-content-space-side;

        font-size: 1.05rem;
    }

    // contains the project description text
    .card-description {

        // expand to fill all the space,
        // so that description can be long,
        // and title will be at the very bottom
        flex-grow: 1;
        // position: relative;

        // margin: 0 $portfolio-item-content-space-side 2rem $portfolio-item-content-space-side;
        // padding: 0;

        // font-size: 14px;
        // line-height: 1.4;

        //transition-property: opacity;
        //transition-duration: $duration-fast;
        //transition-timing-function: $card-hover-transition-function-default;
    }

    // contains the company and project name
    .card-info {
        // position: absolute;
        // position: relative;

        margin-top: 1rem;
        // margin: 0 $portfolio-item-content-space-side;
        // padding: 0 $portfolio-item-content-space-side $portfolio-item-inside-margin-bottom $portfolio-item-content-space-side;
        // padding-bottom: $portfolio-item-inside-margin-bottom;
        // font-size: 16px;
        // line-height: 1.4;

        // color: $portfolio-item-text-color;
    }

    // company name
    .card-projectcompany {
        margin-top: .7rem;
        // font-size: inherit;
        // font-size: 1.25rem;
        font-weight: $font-weight-normal;
    }

    // project name
    .card-projectname {
        margin: 2px 0 0 0;
        // font-size: inherit;
        // font-size: 1.25rem;
    }

    // layer of pure color. an attempt to optimize animation
    // using opacity of layers, instead of transforming the background-color.
    // will change the apparent background-color of the card
    .card-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        // width: 100%;
        left: 0;
        right: 0;
        opacity: 0;
        // will-change: opacity;
        visibility: hidden;

        $durationTime: math.div($duration-fast, 2);

        // transition-property: opacity;
        // transition-duration: ($duration-fast / 2);  // cut in half because the color difference in background-color between normal and hovered is very small
        // transition-timing-function: $card-hover-transition-function-default;
        transition: opacity $durationTime $card-hover-transition-function-default;

        border-radius: $portfolio-card-border-radius;

        &.light {
            background-color: $portfolio-card-background-color-light;
        }

        &.dark {
            background-color: $portfolio-card-background-color-dark;
        }
    }

    // image-overlay is a layer over the image to adjust (hack) the image effects
    .image-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;


        // default opacity
        // opacity: 0;
        opacity: $image-overlay-default-opacity;

        // duration applies when transitioning to default state (i.e. this rule. opacity: 0)
        // transition-duration: ($duration-slow * .6);
        transition-duration: $image-overlay-duration-default;
        // will-change: opacity;

        &.light {
            background-color: $image-overlay-background-color-light;
        }
        &.dark {
            background-color: $image-overlay-background-color-dark;
        }
        // background-color: $image-overlay-background-color-dark;
    }
}


@include media-breakpoint-up(md) {
    .portfolio-item {
        .card {
            .card-image {
                height: $gallery-image-max-height-md;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .portfolio-item {
        .card {
            .card-image {
                height: $gallery-image-max-height-lg;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .portfolio-item {
        .card {
            .card-image {
                height: $gallery-image-max-height-xl;
            }
        }
    }
}

// @keyframes cardbeat {
//     from {
//         transform: scale(1);
//     }
//     20% {
//         transform: scale(1.017);
//     }
//     39% {
//         transform: scale(0.99);
//     }
//     50% {
//         transform: scale(1);
//     }
//     62% {
//         transform: scale(1.017);
//     }
//     75% {
//         transform: scale(0.99);
//     }
//     85% {
//         transform: scale(1);
//     }
//     to {
//         transform: scale(1);
//     }
// }
