// styles for the page scrollfix thingy

// some values defined for the site (_variables.scss)
// $homepage-primarynavbar-height = 60
// $project-primarynavbar-height  = 40
// $project-h2-margin-top:     3rem;
// $project-h3-margin-top:     2.5rem;


// value to represent the size of one rem in the document
$rem-size: 14px;

// this value represents the margin-top set for headings h2 & h3 (in main.scss)
$heading-margin-top:          $project-h2-margin-top-md;


// hidden space above sections. applicable when jump-scrolling to the section
// should be adjusted according to the page header height and also the space about the following sections' heading


// base size of space that should be above a heading for anchor scrolling
$scrollspace-base:                      105px;
$scrollspace-base-sm:                   90px;
// (base size) - (the height of the margin above the h2)
$height-top-scrollFix:                  $scrollspace-base - (getUnitless($heading-margin-top) * $rem-size);

$scrollFix-margin-top-adjustment:       (getUnitless($heading-margin-top)) * $rem-size;
$scrollFix-height-adjustment-md:        10px;   // space to adjust the scrollfix height for smaller window sizes

$target-highlight-color:                $about-background-color;

$h2-height-adjustment:          21px;  // 1.5rem
$h3-height-adjustment:          7px;   // 0.5 rem

// calculate how much margin space to adjust the scrollfix element.
// because the ::before scrollfix will be within the heading margin, while the javascript created eleemnt will be outside the margin,
// and therefore will be farther up the page than intended.
// the height and margin of .scrollfix are going to be using px, so any adjustment needs to convert other units to px
// i.e. the ::before scrollFix height === .scrollFix height

// use for the ::before element

@function scrollFix-before-height ($base: $scrollspace-base, $heading-margin-top: 2.5rem){
    //$marginValue:       getUnitless($heading-margin-top);   // margin top. unitless value
    //$marginPx:  $marginValue * $rem-size;
    $height: $base - (getUnitless($heading-margin-top) * $rem-size);
    @return $height;
}

// use for the generated .scrollFix element
@function element-scrollFix-height ($heightAdjustment: 0){
    $heightOfBefore:    scrollFix-before-height();          // first get the height of the ::element. in px

    $marginValue:       getUnitless($heading-margin-top);   // margin top. unitless value
    $height:            $heightOfBefore - ($marginValue * $rem-size);  // in px
    $height:            $height - $heightAdjustment;

    @warn ($height);
    @return $height;
}

@mixin scrollFix-content-common() {
    &::before {
        display: block;
        visibility: hidden;
        content: "";
    }
}

// calculate the ::before height
// base = the starting measure for the height
//   subtracts 2 * $heading-margin from the base height
//
@mixin scrollFix-content-before($base, $heading-margin) {
    &::before {
        height: scrollFix-before-height($base, $heading-margin);
        margin-top: - (scrollFix-before-height($base, $heading-margin));
    }
}


.theme-project {
    section,
    h2[data-toc],
    h3[data-toc] {
        position: relative;

        // fix:
        // dont display the visible outline box around text headings when
        // they have received a tabindex value after an anchor link was used in the page.
        :focus {
            outline: none;
        }
    }
}

// 'hidden' positioning above content sections.
// fix for when jump-scrolling to the section, the content ends position below the header.
// doesnt work if section element has border or padding-top

.theme-project {
    h2[data-toc],
    h3[data-toc] {
        @include scrollFix-content-common;
    }
}

// bigger
@include media-breakpoint-up(md) {
    .theme-project {
        h2[data-toc],
        h3[data-toc] {
            @include scrollFix-content-before ($scrollspace-base, 2rem);
        }
    }
}

// smaller
@include media-breakpoint-down(md) {
    .theme-project {
        h2[data-toc],
        h3[data-toc] {
            @include scrollFix-content-before ($scrollspace-base-sm, 2rem);
        }
    }
}


// this will invalidate the &::before {scrollfix} on element
// applied by page-section-anchor-replace.js
// -- use in order so that the page can load with the ::before scrollfix content,
// -- then the script will swap it out, and apply this class.
// -- this sequence done so that the elements dont 'jump'
// .nobefore {
//     &::before {
//         content: none !important;
//     }
// }


// small animation for the heading when using anchor links in page
@keyframes highlightTheTarget {
    0% {
        color: inherit;
    }
    5% {
        color: $target-highlight-color;
    }
    100% {
        color: inherit;
    }
}

// little animation that will affect a content heading when anchor scrolling to that section from a content nav link

.theme-project {
    h2[id]:target {
        &[data-toc] {
            animation: highlightTheTarget 1s ease-in-out 0s 1;
        }
    }
}
