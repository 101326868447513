// styles
// GENERAL headings

$page-h1-font-size-xl:      3.5rem;

$project-h2-font-size-lg:   2.0rem;
$project-h2-font-size-md:   1.91rem;

$project-h3-font-size-lg:   1.6rem;
$project-h3-font-size-md:   1.47rem;

$project-h4-font-size-lg:   1.23rem;
$project-h4-font-size-md:   1.12rem;

$project-h5-font-size:      inherit;

$project-h2-margin-top-md:     3.25rem;
$project-h2-margin-top-sm:     2.25rem;
$project-h3-margin-top-md:     2.75rem;
$project-h3-margin-top-sm:     1.75rem;
$project-h4-margin-top-md:     1.35rem;
$project-h4-margin-top-sm:     1rem;


// HEADINGS
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
b, strong, dt {
    font-weight: $font-weight-semibold;
}

// bigger
@include media-breakpoint-up(md) {
    .h2, h2 {
        font-size: $project-h2-font-size-lg;
    }

    .h3, h3 {
        font-size: $project-h3-font-size-lg;
    }

    .h4, h4 {
        font-size: $project-h4-font-size-lg;
    }
}

// smaller
@include media-breakpoint-down(md) {
    .h2, h2 {
        font-size: $project-h2-font-size-md;
    }

    .h3, h3 {
        font-size: $project-h3-font-size-md;
    }

    .h4, h4 {
        font-size: $project-h4-font-size-md;
    }
}

.h2, h2 {
    letter-spacing: -0.02rem;
}

.h5, h5 {
    font-size: $project-h5-font-size;
    margin: 1.1rem 0 1px 0;
}

h2 {
    margin: $project-h2-margin-top-md 0 .9rem 0;
}
.h2 {
    margin: $project-h2-margin-top-md 0 .3rem 0;
}

h3 {
    margin: $project-h3-margin-top-md 0 .4rem 0;
}
.h3 {
    margin: $project-h3-margin-top-md 0 .3rem 0;
}

h4 {
    margin: $project-h4-margin-top-md 0 .3rem 0;
}
.h4 {
    margin: $project-h4-margin-top-md 0 .23rem 0;
}

// h5 {
//     margin: 1.1rem 0 1px 0;
// }
// .h5 {
//     margin: 1.1rem 0 1px 0;
// }
