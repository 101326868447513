// WIDGET:
// image grid widget
// .row-$x = the number of cells across in a row

@use "sass:math";

$cell-margin-between:   2rem;
$cell-margin-bottom:    1.25rem;

// utility function
// calculate the amount of margin space there will be in total between itemns in a row with $n number of items

@function calculate-margin-space($n) {
    @return ( ($n - 1) * $cell-margin-between);
    // @return ($n * $cell-margin-between) - $cell-margin-between;
}

@function calculate-percentage($n) {
    $value: 0;

    @if $n == 1 {
        $value: 100%;
    }
    @else {
        $value: (100%/#{$n});
    }
    @return $value;
}


// revised/improved version of the calc definition
@mixin cell-width ($numberOfItems: 1) {

    $percentage: calculate-percentage($numberOfItems);

    $marginSpaceTotal: calculate-margin-space($numberOfItems);
    $marginSpaceUnits: getUnitValue($marginSpaceTotal);
    $marginSpaceValue: getUnitless($marginSpaceTotal);
    // $marginForEach: ($marginSpaceValue/$numberOfItems);
    $marginForEach: math.div($marginSpaceValue, $numberOfItems);

    $widthValue: "";

    // if marginForEach = 0, then dont need the units, nor does it need to be output
    @if $marginForEach != 0 {
        $marginForEach: $marginForEach + $marginSpaceUnits;
        $widthValue: "calc(#{$percentage} - #{$marginForEach})";
    } @else {
        // width: calc(#{$percentage});
        $widthValue: "#{$percentage}";
    }

    // @debug "cell-width: #{$widthValue}";
    width: #{$widthValue};
}


// scss array for # of items in a row
$grid-row-items: (
    2: 2,
    3: 3,
    4: 4,
);


// for general grid class
.image-grid {
    display: flex;

    .figure {
        overflow: hidden;
    }
}


// VERSION 1
// the original grid
// uses width: calculated width for the cells
.image-grid-widthtype {

    flex-wrap: wrap;
    justify-content: space-between;

    .image-cell {
        max-width: 100%;
        margin-bottom: $cell-margin-bottom;
    }

    &.row-2x {
        .image-cell {
            margin-bottom: 0rem;
        }
    }

//     @each $size, $numberOfItems in ($grid-row-items) {
//         &.row-#{$numberOfItems}x {
//             .image-cell {
//                 @include cell-width($numberOfItems);
//             }
//
//             .image-cell:nth-of-type(#{$numberOfItems}n) {
//                @include row-margin-remove;
//             }
//         }
//     }
}

// media queries so that
// medium screens: grid width is 1-column
// larger: grid width is set to accomodate # of items in row.
@include media-breakpoint-down(md) {
    .image-grid-widthtype {
        @each $size, $numberOfItems in ($grid-row-items) {
            &.row-#{$numberOfItems}x {
                .image-cell {
                    @include cell-width(1);
                }
            }
        }

        // remove margin underneath when stacked
        .image-cell {
            margin-bottom: 0;
        }
    }
}

// breakpoint
@include media-breakpoint-up(lg) {
    .image-grid-widthtype {
        @each $size, $numberOfItems in ($grid-row-items) {
            &.row-#{$numberOfItems}x {
                .image-cell {
                    @include cell-width($numberOfItems);
                }

    //             .image-cell:nth-of-type(#{$numberOfItems}n) {
    //                @include row-margin-remove;
    //             }
            }
        }
    }
}

//     @each $size, $numberOfItems in ($grid-row-items) {
//         &.row-#{$numberOfItems}x {
//             // removing the spacing margin from N-th item in a row of N
//             .image-cell:nth-of-type(#{$numberOfItems}n) {
//                @include row-margin-remove;
//             }
//         }
//     }



// scss value array for height options
$image-heights: (
    small:  60px,
    medium: 100px,
    large:  200px,
    auto:   auto,
);

// scss value array: image widths for different size values
$image-widths: (
    half: 50%,
    full: 100%,
);

.image-grid {
    @each $size, $imgHeight in ($image-heights) {
        .height-#{$size} {
            height: #{$imgHeight};
        }
    }

    @each $size, $width in ($image-widths) {
        .width-#{$size} {
            width: #{$width};
        }
    }
}

// override of mobile-image handling for the image-grid display
.image-grid-widthtype {
    &.row-2x {
        .figure-mobile {
            width: 100%;
        }
    }
}

// breakpoint
// at smaller sizes, the image can be larger
@include media-breakpoint-down(md) {
    .image-grid-widthtype {
        &.row-2x {
            .figure-mobile {
                width: 75%;
            }
        }
    }
}


// VERSION 2
// redo of the image grid, using more all flexbox
// uses flexbasis: calculated

// unline version1, this version has a left & right margin on each item (image cell) in the group.
// total margin will be $image-item-margin-on-each
// and the total margin amount for a row will be #number-of-items in the row,
// not #number-of-items - 1, like version1.

$image-item-margin-between: 2rem;
$image-item-margin-on-each: 2rem;
$image-item-margin-bottom: 1.25rem;

$imageMarginTemp: math.div($image-item-margin-on-each, 2);

// @function calculate-margin-space-flex($n) {
//     @return ( ($n) * $image-item-margin-on-each);
// }

// @mixin width-of-each ($numberOfItems: 1) {

//     $percentage: calculate-percentage($numberOfItems);

//     $marginSpaceTotal: calculate-margin-space-flex($numberOfItems);
//     $marginSpaceUnits: getUnitValue($marginSpaceTotal);
//     $marginSpaceValue: getUnitless($marginSpaceTotal);
//     $marginForEach: ($marginSpaceValue/$numberOfItems);

//     $widthValue: "";

//     // if marginForEach = 0, then dont need the units, nor does it need to be output
//     @if $marginForEach != 0 {
//         $marginForEach: $marginForEach + $marginSpaceUnits;
//         $widthValue: "calc(#{$percentage} - #{$marginForEach})";
//     } @else {
//         // width: calc(#{$percentage});
//         $widthValue: "#{$percentage}";
//     }
//     @debug "widthValue: #{$widthValue}";
//     flex-basis: #{$widthValue};
// }


.image-grid-flextype {
    // background-color: #Ffe0e0;  // for testing

    // flex-wrap: wrap;
    justify-content: center;

    // remove a margin from left and right sides of the flex container
    margin: 0 (-($imageMarginTemp)) 0 (-($imageMarginTemp));

    .image-item {
        // background-color: #CCFFC0;  // for testing
        // flex: 1;
        // margin-bottom: $cell-margin-bottom;

        margin: 0 $imageMarginTemp 0 $imageMarginTemp;
    }
}

// @each $size, $numberOfItems in ($grid-row-items) {
//     &.row-#{$numberOfItems}x {
//         .image-item {
//             @include width-of-each($numberOfItems);
//         }
//     }
// }

// @include media-breakpoint-up(lg) {
//     @each $size, $numberOfItems in ($grid-row-items) {
//         &.row-#{$numberOfItems}x {
//             .image-item {
//                 @include width-of-each($numberOfItems);
//             }
//         }
//     }
// }


// breakpoint
// at smaller sizes, the image can be larger
// @include media-breakpoint-down(md) {
    // .image-grid-flextype {}
// }

