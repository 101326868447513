/*!
 *  Font Awesome 4.7.0 by @davegandy - http://fontawesome.io - @fontawesome
 * License - https://fontawesome.com/license (Commercial License)
 */

@use "_screen-reader";
@use "_animated";

@import "_variables";
@import "_mixins";

@import "_path";
@import "_core";
@import "_larger";
// @import "_fixed-width";
// @import "list";
// @import "_bordered-pulled";

// @import "rotated-flipped";
// @import "stacked";
@import "_icons";
