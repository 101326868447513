// SCSS partial
//
// Tables
// modified version of the default bootstrap 4 _tables.scss file
// styles not modified have been commented out, therefore the default bootstrap values will apply
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .75rem !default;
$table-cell-padding-sm-custom:  .4rem;
$table-bg:                      transparent !default;

$table-head-bg:                 $gray-lighter !default;
$table-head-color:              #666663;

$table-border-width:            $border-width !default;
$table-border-color:            $gray-lighter !default;



.table {
//   width: 100%;
//   margin-bottom: $spacer;
//   color: $table-color;
//   background-color: $table-bg; // Reset for nesting within parents with `background-color`.
//
//   th,
//   td {
//     padding: $table-cell-padding;
//     vertical-align: top;
//     border-top: $table-border-width solid $table-border-color;
//   }
//

// add a border at the bottom of the table to close it
  tr {
    &:last-of-type {
        td {
        border-bottom: $table-border-width solid $table-border-color;
        }
    }
  }

  thead th {
    font-weight: $font-weight-semibold;
    // vertical-align: bottom;
    // border-bottom: (2 * $table-border-width) solid $table-border-color;
  }
//
//   tbody + tbody {
//     border-top: (2 * $table-border-width) solid $table-border-color;
//   }

}


//
// Condensed table w/ half padding
//

.table-sm {
  th,
  td {
    padding: $table-cell-padding-sm-custom;
  }
}

// Dark styles
//
// Same table markup, but inverted color scheme: dark background and light text.

// stylelint-disable-next-line no-duplicate-selectors
.table {
//   .thead-dark {
//     th {
//       color: $table-dark-color;
//       background-color: $table-dark-bg;
//       border-color: $table-dark-border-color;
//     }
//   }

  .thead-light {
    th {
      color: $table-head-color;
      background-color: $table-head-bg;
      // border-color: $table-border-color;
    }
  }
}
