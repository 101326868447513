// SCSS partial
//
// beckoning 'arrow' on home/index page
// relate closely to the background of the following 'about' section
//
// **********

@use "sass:math";

$flame-color:                   darken($about-background-color, 6.6667%);
$flame-shadow-color:            #EFD;

$flame-flicker-duration:        2s;
$flame-flicker-delay:           1s;
$flame-flicker-iteration:       infinite;
$flame-flicker-timing-function: ease-in;

$flame-flicker-degree:          0.2deg;
$flame-flicker-scale-x-min:     0.98;
$flame-flicker-scale-x-max:     1.03;
$flame-flicker-scale-y:         1.02;
$flame-flicker-scale-y-max:     1.031;
$flame-flicker-scale-y-min:     0.96;

$flame-position-y:      1rem;
$flame-position-y-md:   8%;

$flame-width:       113px;
$flame-height:      117px;

.explore {
    position: absolute;
    bottom: $flame-position-y;
    left: 50%;
    margin-left: -(math.div($flame-width, 2));
}

#leaderflame {
    // inline svg attributes
    width: $flame-width;
    height: $flame-height;
    fill: $flame-color;

    transform-origin: bottom;
    animation: flicker $flame-flicker-duration infinite ease-in $flame-flicker-delay alternate;

    filter: drop-shadow(0px -7px 10px $flame-shadow-color);
}

@keyframes flicker {
//   from {
//     transform: rotate(-$flame-flicker-degree);
//     }
//   20% {
//     transform: rotate($flame-flicker-degree) scaleX($flame-flicker-scale-x-min);
//     }
//   40% {
//     transform: rotate(-$flame-flicker-degree);
//     }
//   60% {
//     transform: rotate($flame-flicker-degree) scaleY($flame-flicker-scale-y) scaleX($flame-flicker-scale-x-max);
//     }
//   80% {
//     transform: rotate(-$flame-flicker-degree) scaleY( 1/$flame-flicker-scale-y);
//     }
//   to {
//     transform: rotate($flame-flicker-degree);
//     }
    0% {
        transform: scaleX($flame-flicker-scale-x-max);
    }
    11% {
        transform: scaleY(1.031);
    }
    21% {
        transform: scaleX(1);
    }
    40% {
        transform: scaleY($flame-flicker-scale-y-min);
    }
    50% {
        transform: scaleX(1.04);
    }
    61% {
        transform: scaleY(1);
    }
    100% {
        transform: scale(1);
    }
}

// media breakpoint:
// adjust position on small screens

@include media-breakpoint-down(md) {
    .explore {
        bottom: $flame-position-y-md;
    }
}
